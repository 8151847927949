import React from 'react'
import { config, useSpring, animated } from 'react-spring'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import styled from 'styled-components'
import { Box, AnimatedBox, Button } from '../elements'
import SEO from '../components/SEO'
import Covid19 from '../components/Covid19'

  const Pagetag = styled(AnimatedBox)`
    letter-spacing: 0.05em;
    font-size: ${props => props.theme.fontSizes[1]};
    text-transform: uppercase;
  `


const PBox = styled(AnimatedBox)`
    max-width: 1400px;
    margin: 0 auto;
  `
  const PButton = styled(Button)<{ color: string }>`
    background: #ffec00;
    color: black;
    :hover {
      background: black;
      color: white;
      transition: 0.6s ease 0s !important;
      cursor: pointer;
}
  `

  const EndLine = styled(Box)`
      max-width: 500px;
      margin: 0 auto;
      border-bottom: 2px solid #ffec00;
    `

const CovidContent = `

An initiative under the Government’s National Digital Strategy, offering up to €2500,00 to help SMEs get their buinesses online or update their exisiting web presence.

Trading Online Voucher Scheme funding can be used towards adding payment facilities or booking systems to your website or developing new apps for your customers.

The voucher can also be used towards subscriptions to low-cost online retail platform solutions to help companies quickly establish a retailing presence online.

<span>We beleive that if Irish businesses continue to support each other we can all get thorugh this together.</span>


At ASC we've already assisted many SME's in updating their online presence, developed payment solutions and automated manual processes to ensure our clients are well equppied to face the challanges ahead.

We can provide a complete solution, instructional documentation, staff training and ongoing support.


<span>Do you Qaulify?</span>
<ul>
  <li>Less than €2m in turnover.</li>
  <li>Limited or no e-commerce presence</li>
  <li>Trading for at least 6 months and,</li>
  <li>Located in the region of the local enterprise office to whom they are applying.</li>
</ul>

Over 2000+ businesses have already availed of this scheme. With initial feedback from LEO suggesting an increase in sales of 20% and many more businesses exporting to markets outside Ireland.

From our own clients, we can see the benifits first hand, with some now exclusive trading online, having never done so before!

Speak to your Local Entprise Office to express your interest.
You can locate your nearest office <a href="https://www.localenterprise.ie/Portal/Find-Your-Local-Enterprise-Office/">here</a>.
And find further informaton on the Trading Online Voucher Scheme <a href="https://www.localenterprise.ie/Discover-Business-Supports/Trading-Online-Voucher-Scheme-/" target="_blank">here</a>.

For advice or to discuss your businesses needs please <a href="https://ascreative.studio" target="_blank">contact us</a>. We will be here to advise and assist you all along the way.
`

const formattedDescription = CovidContent.split(`\n\n`).map(paragraph => `<p>${paragraph.replace(/\n/g, `<br>`)}</p>`).join(``)

const Covid = () => {
  const pageAnimation = useSpring({
    config: config.slow,
    delay: 800,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  const pagetagAnimation = useSpring({
    config: config.slow,
    delay: 600,
    from: { opacity: 0, transform: 'translate3d(0, -30px, 0)' },
    to: { opacity: 1, transform: 'translate3d(0, 0, 0)'},
  })

  const covid19Animation = useSpring({
    config: config.slow,
    delay: 1000,
    from: { opacity: 0, transform: 'translate3d(0, 0, 0)' },
    to: { opacity: 1, transform: 'translate3d(0, -60px, 0)'},
  })

  return (
    <Layout color="#ffec00">
      <SEO title="Covid-19 Support | ASC Studio" desc="ASC Studio, Leading Dublin Creative Studio in Web Design, Brand Idenity, Interiors & Bespoke Furniture." />
      <PBox py={10} px={[6, 6, 8, 10]}>
        <AnimatedBox style={pageAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 8, 13]}>
        <AnimatedBox style={covid19Animation }py={3} width={['4.5rem', '5.5rem', '6.5rem', '7.5rem']}>
        <Covid19 />
        </AnimatedBox>
        <Pagetag style={pagetagAnimation}>Do you Know</Pagetag>
        <animated.h1 style={pageAnimation}>Support available for businesses impacted by COVID-19</animated.h1>
            <p dangerouslySetInnerHTML={{ __html: formattedDescription }} />
          </AnimatedBox>
      </PBox>
      <EndLine />
      <PBox style={{ textAlign: 'center' }} py={8} px={[6, 6, 8, 10]}>
        <h3>Have questions, want to get started?</h3>
        <Link to="/contact">
        <PButton color="#ffec00" py={3} px={8}>
          Contact Us
        </PButton>
        </Link>
      </PBox>
    </Layout>
  )
}

export default Covid
