import React from 'react'

const Covid19 = () => (
  <svg viewBox="0 0 498.2 498.2">
    <title>Covid-19</title>
    <path d="M0,309.1c0,0.1,0,0.2,0,0.3l0,0.2c0,0.1,0,0.2,0,0.3l0,0.3c0,0.2,0,0.4,0,0.5c0,0.4,0.1,0.8,0.1,1.2
    	c0.1,0.5,0.2,1,0.3,1.6c0,0.2,0.1,0.4,0.1,0.6c0.1,0.5,0.2,1.1,0.4,1.7c0.3,0.9,0.5,1.8,0.8,2.7c0.1,0.3,0.3,0.7,0.4,1
    	c0.1,0.2,0.1,0.3,0.2,0.5c0,0.1,0.1,0.2,0.1,0.4l0.1,0.2c0.1,0.3,0.3,0.5,0.4,0.8l0.5,1c0.4,0.6,0.7,1.2,1.1,1.7
    	c0.2,0.3,0.4,0.5,0.7,0.8c0,0,0.1,0.1,0.1,0.1c0.4,0.4,0.8,0.7,1.2,0.9c0.3,0.2,0.5,0.3,0.8,0.4c0.2,0.1,0.5,0.1,0.7,0.1
    	c0.2,0,0.5,0,0.7-0.1l0.1,0c0.1,0,0.3-0.1,0.4-0.1c0.3-0.1,0.5-0.2,0.8-0.3c0.1-0.1,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.1,0.4-0.2
    	c0.7-0.4,1.4-0.9,1.9-1.4c0.1-0.1,0.2-0.2,0.3-0.4c0.2-0.2,0.4-0.5,0.5-0.7c0.1-0.1,0.1-0.2,0.2-0.4l0.1-0.1c0-0.1,0.1-0.2,0.2-0.3
    	c0.1-0.2,0.3-0.5,0.6-0.7c0.1-0.1,0.2-0.2,0.3-0.3l0.2-0.2c0.3-0.3,0.7-0.6,1.1-1c0.2-0.2,0.4-0.3,0.7-0.5l0.4-0.3
    	c0.2-0.2,0.5-0.3,0.8-0.5c0.3-0.2,0.6-0.4,0.8-0.5c0.1-0.1,0.3-0.2,0.4-0.2l0.5-0.3c0.3-0.2,0.6-0.3,0.9-0.5
    	c0.1-0.1,0.3-0.2,0.4-0.2l0.1,0c0.7-0.4,1.4-0.7,2-1c0.6-0.3,1.1-0.5,1.6-0.7c0.2-0.1,0.4-0.2,0.6-0.2l0.1,0c0,0,0.1,0,0.1-0.1
    	l0.1,0c0,0,0.1,0,0.1,0l0.7-0.3c0.1,0,0.1,0,0.2-0.1c0.2-0.1,0.3-0.1,0.5-0.2l0.2-0.1c0.1,0,0.1,0,0.1-0.1l0.7-0.2
    	c0.1,0,0.2-0.1,0.2-0.1c0.2-0.1,0.4-0.1,0.6-0.2c0.1,0,0.2-0.1,0.4-0.1l0.7-0.2c0.1,0,0.2-0.1,0.3-0.1l0.2-0.1
    	c0.1,0,0.2-0.1,0.4-0.1l1.6-0.5c0.1,0,0.3-0.1,0.4-0.1l0.1-0.1c0.1,0,0.2-0.1,0.3-0.1c0.2-0.1,0.4-0.1,0.6-0.2l0.4-0.1
    	c0.2-0.1,0.4-0.1,0.7-0.2c0.1,0,0.2-0.1,0.4-0.1l0.8-0.2c0.1,0,0.1,0,0.2-0.1l0.2-0.1c0.2-0.1,0.5-0.1,0.7-0.2l0.1,0
    	c0.1,0,0.2-0.1,0.2-0.1l0.1,0c0.2-0.1,0.4-0.1,0.7-0.2l0.5-0.1c0.3-0.1,0.5-0.2,0.8-0.2l0.5-0.1c0.2-0.1,0.5-0.1,0.7-0.2
    	c0.1,0,0.2-0.1,0.3-0.1l0.8-0.2c0.1,0,0.3-0.1,0.4-0.1l0.4-0.1c0.2-0.1,0.3-0.1,0.4-0.1c0.1,0,0.3-0.1,0.4-0.1l4-1
    	c0.2,0,0.3-0.1,0.4-0.1l1.5-0.4c0.3-0.1,0.6-0.1,0.9-0.2l0.1,0c0.1,0,0.1,0,0.2-0.1l0.9-0.2c0.1,0,0.2-0.1,0.3-0.1l0.4-0.1
    	c0.2,0,0.3-0.1,0.5-0.1l0.2-0.1c0.3-0.1,0.6-0.1,0.9-0.2l0.5-0.1c0.2-0.1,0.5-0.1,0.7-0.2l0.2,0c0.3-0.1,0.6-0.1,0.9-0.2l0.2,0
    	c0.3-0.1,0.6-0.1,0.9-0.2c0.1,0,0.2,0,0.3-0.1l0.3-0.1c0.1,0,0.3-0.1,0.4-0.1l0.8-0.1c1.1-0.2,2.4-0.5,3.6-0.7l0.3-0.1
    	c0.2,0,0.4-0.1,0.5-0.1c0.1,0,0.1,0,0.2,0l0.1,0c0.3-0.1,0.6-0.1,0.9-0.1l0.5-0.1c0.2,0,0.3-0.1,0.5-0.1c0.2,0,0.4-0.1,0.6-0.1
    	l0.5-0.1c0.2,0,0.4-0.1,0.6-0.1c0.1,0,0.2,0,0.3,0l0.6-0.1l0.2,0.1l0.2-0.1l0.8-0.1c0.1,0,0.2,0,0.4,0l0.3,0c0,0,0.1,0,0.1,0h0.1
    	l0.2,0.4l0.7-0.4l0.1,0c0.1,0,0.2,0,0.3,0c0.2,0.4,0.5,1,1,2.3l0,0.1c1.2,3.8,3,8.6,5.2,13.8c1.4,3.3,2.9,6.7,4.5,10
    	c1.1,2.4,2.2,4.5,3,6.1c0,0.1,0.1,0.2,0.1,0.3c0.5,0.9,0.8,1.6,0.9,2.1c-0.1,0.1-17.4,7.9-19.6,8.8c-5.9-7-13-9.8-17.9-7
    	c-6.1,3.5-6.2,14.4-0.2,24.8c6,10.4,15.5,15.8,21.6,12.3c4.6-2.6,5.9-9.8,3.2-18c0.7-0.5,17.2-13.1,17.4-13.2
    	c0.1,0.1,0.2,0.3,0.3,0.5c1.8,3,4.6,7.1,8,11.7c4.3,5.9,8.8,11.5,12.4,15.4l0,0c0.1,0.1,0.1,0.2,0.2,0.2c0.8,1,1.2,1.6,1.4,1.9
    	l-0.4,0.6c-0.4,0.6-0.8,1.2-1.2,1.8c-0.5,0.7-1.1,1.5-1.8,2.4c-0.3,0.3-0.5,0.7-0.8,1l-1.2,1.4l0,0l-1.7,2c-0.1,0.2-0.3,0.3-0.4,0.5
    	l0,0c-0.1,0.1-0.2,0.2-0.3,0.3l-0.4,0.5c-0.1,0.1-0.2,0.2-0.2,0.2c-0.1,0.1-0.2,0.2-0.3,0.3l-0.2,0.2c0,0,0,0-0.1,0.1
    	c-0.1,0.1-0.3,0.3-0.4,0.4l-0.4,0.4c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.3,0.3-0.4,0.4l-0.7,0.7c-0.1,0.1-0.1,0.1-0.2,0.2l-1.1,1.2
    	c-0.1,0.1-0.2,0.2-0.4,0.4l-0.3,0.3c-0.1,0.1-0.2,0.3-0.4,0.4l-0.4,0.4c-0.1,0.1-0.2,0.2-0.3,0.3l-0.4,0.4c-0.1,0.1-0.3,0.3-0.4,0.4
    	l-1.1,1.1c-0.1,0.1-0.1,0.1-0.2,0.2l-1,1c0,0,0,0,0,0l-1.1,1.1c-0.2,0.2-0.3,0.4-0.5,0.5l-0.7,0.7c-0.1,0.1-0.3,0.3-0.4,0.4
    	l-0.1,0.1c-0.2,0.2-0.3,0.3-0.5,0.5l-0.1,0.1c-0.1,0.1-0.2,0.2-0.4,0.4l-0.1,0.1c0,0-0.1,0.1-0.1,0.1l-0.6,0.5
    	c-0.1,0.1-0.1,0.1-0.2,0.2l-2.1,2c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.2-0.3,0.3-0.5,0.4
    	c-0.1,0.1-0.1,0.1-0.2,0.2l-0.2,0.1c-0.1,0.1-0.1,0.1-0.2,0.2l-0.2,0.2c-0.1,0.1-0.2,0.2-0.4,0.4l-0.3,0.3c-0.1,0.1-0.1,0.1-0.2,0.1
    	l-0.3,0.3c-0.1,0.1-0.2,0.2-0.3,0.3l-0.5,0.4c-0.1,0.1-0.1,0.1-0.2,0.2l-0.4,0.4c-0.1,0-0.1,0.1-0.2,0.2L84,407
    	c-0.1,0.1-0.1,0.1-0.2,0.2c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.2,0.2-0.3,0.2l-0.4,0.3c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0.1-0.1,0.1
    	l-1.2,1c-0.1,0.1-0.2,0.1-0.3,0.2l-0.2,0.1c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.2-0.3,0.3l-0.5,0.4
    	c-0.2,0.2-0.5,0.4-0.7,0.5L79,411c-0.3,0.2-0.6,0.5-1,0.7c-0.6,0.4-1.3,0.9-1.9,1.3c-0.3,0.2-0.6,0.4-0.9,0.6
    	c-0.3,0.2-0.6,0.4-0.9,0.5c-2.4,1.3-4.4,2.1-5.7,2.3c-0.4,0-0.6,0-0.7,0c-0.3-0.1-0.6-0.1-1-0.1c-0.1,0-0.2,0-0.2,0
    	c-0.3,0-0.6,0.1-0.8,0.2c0,0-0.1,0-0.1,0c-0.1,0-0.2,0.1-0.4,0.1c-0.3,0.2-0.7,0.3-1,0.5c-0.2,0.1-0.5,0.3-0.7,0.4
    	c-0.1,0.1-0.2,0.2-0.3,0.2c0,0-0.1,0.1-0.1,0.1c-0.2,0.1-0.3,0.3-0.5,0.4c0,0-0.1,0.1-0.1,0.1l-0.1,0.1c0,0,0,0-0.1,0.1l-0.4,0.2
    	l-0.1,0.2c-0.2,0.3-0.3,0.6-0.4,1c0,0.2-0.1,0.4-0.1,0.7c0,0.1,0,0.2,0,0.3c0,0.2,0,0.4,0.1,0.5c0,0,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2
    	l0,0.2c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.3,0.1,0.4c0.1,0.2,0.2,0.5,0.3,0.8c0.1,0.2,0.2,0.5,0.4,0.8L63,425
    	l0.5,0.8c0.1,0.1,0.1,0.2,0.2,0.4l0,0.1c0.1,0.1,0.2,0.3,0.3,0.4c0.2,0.3,0.4,0.5,0.6,0.8c0.3,0.4,0.7,0.8,1,1.2
    	c0.6,0.7,1.2,1.4,1.9,2c0.3,0.3,0.6,0.6,0.8,0.8c0.6,0.5,1.1,1,1.7,1.5c0.3,0.2,0.6,0.4,0.8,0.6c1.1,0.8,2.1,1.4,3.1,1.9
    	c0,0,0.1,0.1,0.1,0.1l0.1,0.1c0.2,0.1,0.4,0.2,0.5,0.2l0.1,0c0,0,0.1,0,0.1,0c0.2,0.1,0.4,0.2,0.6,0.2c0.1,0,0.3,0.1,0.4,0.1
    	c0.1,0,0.3,0.1,0.4,0.1c0.4,0.1,0.8,0.1,1.2,0.1c0.2,0,0.5,0,0.7-0.1l0.2,0c0.2-0.1,0.4-0.1,0.6-0.2c0.2-0.1,0.3-0.2,0.5-0.3
    	l0.3-0.2l0.1-0.2c0.1-0.1,0.3-0.3,0.4-0.4c0,0,0.1-0.1,0.1-0.1l0.1-0.1c0.1-0.1,0.1-0.2,0.2-0.2c0.1-0.2,0.3-0.4,0.4-0.6
    	c0.4-0.7,0.8-1.5,1-2.2c0.1-0.3,0.1-0.5,0.1-0.7c0-0.2,0-0.4,0-0.6c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3l0-0.1c0-0.1,0-0.1,0-0.3
    	v-0.2c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.2,0.1-0.3c0-0.2,0.1-0.3,0.1-0.5l0-0.1
    	c0-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.1-0.3,0.2-0.5l0-0.1c0.1-0.2,0.2-0.4,0.2-0.5c0-0.1,0.1-0.1,0.1-0.2L83,426
    	c0.1-0.3,0.2-0.5,0.4-0.8c0.1-0.2,0.3-0.5,0.4-0.7c0-0.1,0.1-0.2,0.2-0.3L84,424c0-0.1,0.1-0.1,0.1-0.2c0.1-0.2,0.2-0.4,0.4-0.6
    	c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.1,0.1-0.3,0.2-0.4c0.8-1.4,1.8-2.7,2.8-4l0.5-0.6c0,0,0-0.1,0.1-0.1l0.6-0.8c0-0.1,0.1-0.1,0.1-0.1
    	l0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.2l0.1-0.1c0.1-0.1,0.1-0.2,0.2-0.2l0.3-0.4c0,0,0.1-0.1,0.1-0.1l0.2-0.2c0.1-0.1,0.2-0.3,0.4-0.4
    	l0.1-0.1c0.1-0.1,0.1-0.2,0.2-0.2l0.5-0.5c0.1-0.1,0.1-0.2,0.2-0.2l0.3-0.3l0.1-0.1l0.1-0.1c0.1-0.1,0.2-0.2,0.2-0.3
    	c0.1-0.2,0.3-0.3,0.4-0.5l0.3-0.3c0.1-0.1,0.2-0.2,0.3-0.4l0.9-0.9c0.1-0.1,0.1-0.1,0.2-0.2l0.6-0.6c0.1-0.1,0.2-0.2,0.3-0.4
    	c0.1-0.2,0.3-0.3,0.5-0.5c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.2,0.3-0.3l0.5-0.5c0.2-0.2,0.3-0.4,0.5-0.6
    	c0.1-0.1,0.2-0.2,0.3-0.3l1.4-1.4c0.1-0.1,0.2-0.2,0.3-0.3l1.7-1.7c0.1-0.1,0.2-0.2,0.3-0.3l0.4-0.4c0,0,0.1-0.1,0.1-0.1
    	c0.2-0.2,0.4-0.4,0.6-0.6l0.9-0.9c0.1-0.1,0.2-0.2,0.2-0.2l1.7-1.7c0.1-0.1,0.3-0.2,0.4-0.4l0.5-0.5c0.2-0.2,0.4-0.4,0.6-0.6
    	c0.1-0.1,0.2-0.1,0.2-0.2c0.2-0.2,0.4-0.4,0.7-0.7l0.8-0.8c0,0,0.1-0.1,0.1-0.1l1.7-1.5c0,0,0.1-0.1,0.1-0.1l0.3-0.2
    	c0.1-0.1,0.3-0.2,0.4-0.4c0,0,0.1-0.1,0.2-0.1l0.7-0.7l0,0l0.4-0.4c1-0.9,1.8-1.6,2.7-2.3l0.8-0.6c0.1-0.1,0.2-0.2,0.2-0.2
    	c0.2-0.2,0.4-0.4,0.7-0.5l0.4-0.3c0.1-0.1,0.1-0.1,0.2-0.2l0.2-0.2c0.1-0.1,0.3-0.2,0.4-0.3l1.1-0.9c0.2-0.1,0.4-0.3,0.6-0.4
    	l0.1-0.1c0,0,0.1-0.1,0.1-0.1l0,0c0.1-0.1,0.2-0.2,0.3-0.2l0.2-0.1c0,0,0.1,0,0.1-0.1h0l0.1-0.1c0.2-0.1,0.4-0.2,0.6-0.4l0.1-0.1
    	c0.2-0.1,0.3-0.2,0.5-0.3l0.3-0.2c0,0,0,0,0.1,0c0.1,0,0.2-0.1,0.3-0.2c0.5,0.3,1.2,0.8,2,1.5l0.1,0.1c2.9,2.7,6.9,6,11.4,9.4
    	c2.9,2.2,5.9,4.4,8.9,6.4c2.2,1.6,4.2,2.9,5.6,3.8l0.1,0.1c0.1,0,0.1,0.1,0.2,0.1c0.9,0.6,1.5,1,1.9,1.4c-0.1,0.1-0.1,0.3-0.2,0.4
    	l-1.5,2.5l0,0c-0.1,0.2-0.2,0.3-0.3,0.5c-1.1,1.4-2.5,3.1-3.8,4.8c-2,2.5-4.1,5.1-5.6,7c-8.5-3.1-16.3-1.9-19,2.9
    	c-1.7,3-1.4,7,1.1,11.1c2.3,4,6.3,7.7,11.1,10.5c4.8,2.8,10.1,4.4,14.6,4.4c4.8,0,8.4-1.6,10.2-4.6c2.7-4.6,0.2-11.4-6.2-17.2
    	c0.3-0.8,0.6-1.7,1-2.7c1.5-4.1,3.5-9.7,5.4-13.9c0.1-0.2,0.2-0.3,0.3-0.5l0.1-0.1c0.2-0.4,0.4-0.6,0.5-0.8c0.1,0.1,0.3,0.1,0.5,0.2
    	c3,1.7,7.6,3.9,13,6.2c6.7,3,13.2,5.5,18.3,7.1l0.3,0.1c1.2,0.5,1.9,0.8,2.2,1l0,0.7c0,0.7-0.1,1.6-0.2,2.5
    	c-0.1,0.9-0.2,1.7-0.3,2.6c-0.1,0.4-0.1,0.8-0.2,1.3l-0.2,1l0,0l-0.3,1.6c0,0.2-0.1,0.4-0.1,0.6c0,0.2-0.1,0.4-0.1,0.6l0,0.2
    	c0,0.2-0.1,0.3-0.1,0.5c0,0.1,0,0.2-0.1,0.3c0,0.1,0,0.2-0.1,0.3l-0.3,1.4c0,0.2-0.1,0.4-0.1,0.7l0,0.1c0,0,0,0.1,0,0.1l-0.6,2.6
    	c0,0.1,0,0.2-0.1,0.3l-0.1,0.5c0,0.1-0.1,0.2-0.1,0.4l0,0.2c0,0.1-0.1,0.3-0.1,0.4l0,0c0,0.1-0.1,0.3-0.1,0.5l-0.4,1.5
    	c0,0.2-0.1,0.4-0.1,0.5c0,0.1-0.1,0.2-0.1,0.4l-0.7,2.9c0,0.2-0.1,0.4-0.2,0.7l-0.6,2.2c0,0.1-0.1,0.2-0.1,0.3l-0.9,3.2
    	c-0.1,0.2-0.1,0.4-0.2,0.6c0,0.1-0.1,0.2-0.1,0.2l-0.1,0.2c0,0.1-0.1,0.2-0.1,0.3l-0.1,0.3c-0.1,0.2-0.1,0.4-0.2,0.7
    	c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.1-0.1,0.2l-0.1,0.4c0,0,0,0.1,0,0.1c-0.1,0.2-0.1,0.5-0.2,0.8l-0.7,2.2c0,0.1,0,0.1-0.1,0.2l0,0
    	c0,0.1,0,0.1-0.1,0.2l-0.7,2.2c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.2-0.1,0.3-0.1,0.4l-0.3,0.8c0,0.1-0.1,0.2-0.1,0.3l-0.1,0.1
    	c0,0.1,0,0.1,0,0.1l-1.3,3.4c0,0,0,0.1,0,0.1c0,0.1-0.1,0.1-0.1,0.2l-0.3,0.7c0,0,0,0,0,0.1l-0.1,0.2c0,0.1,0,0.1-0.1,0.2
    	c-0.1,0.4-0.3,0.7-0.4,1c-0.5,1.1-1,2.2-1.5,3.1c-1.1,2.2-2.3,4-3.4,5.3c-1.2,1.4-1.8,1.6-2,1.6c-0.2,0-0.5,0.1-0.7,0.3l0,0
    	c-0.2,0.1-0.4,0.3-0.6,0.4c-0.4,0.3-0.7,0.8-1,1.3c-0.1,0.2-0.2,0.3-0.3,0.5c-0.3,0.6-0.6,1.3-0.8,1.9c-0.1,0.1-0.1,0.3-0.1,0.4
    	c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c-0.1,0.6,0,1.2,0.3,1.8c0,0,0,0.1,0.1,0.2c0.5,1,1.6,1.9,3.1,2.8c0.2,0.1,0.4,0.2,0.6,0.4
    	c0.4,0.2,0.9,0.5,1.3,0.7c0.6,0.3,1.2,0.5,1.9,0.8c1,0.4,2,0.7,3.2,1c0.6,0.2,1.2,0.3,1.7,0.4c0.3,0.1,0.7,0.1,1,0.2
    	c0.6,0.1,1.1,0.2,1.6,0.2c0.3,0,0.6,0.1,0.9,0.1l0.1,0c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0l0.2,0c0.3,0,0.5,0,0.8,0h0.3
    	c0.1,0,0.2,0,0.3,0c0.4,0,0.9,0,1.4-0.1c0.3,0,0.7-0.1,1.1-0.2c0.1,0,0.2-0.1,0.3-0.1l0.2-0.1c0.1,0,0.1,0,0.2-0.1
    	c0.1,0,0.2-0.1,0.3-0.1c0.9-0.3,1.6-0.8,2-1.4c0.1-0.2,0.2-0.4,0.3-0.7c0.1-0.4,0.2-0.8,0.2-1.2c0-0.1,0-0.3,0-0.4
    	c0-0.1,0-0.2,0-0.2c0-0.2,0-0.4,0-0.6c0-0.2,0-0.4,0-0.6c0-0.8-0.2-1.6-0.4-2.2c0,0,0-0.1-0.1-0.1c-0.1-0.3-0.3-0.6-0.5-0.8
    	c0,0-0.1-0.1-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c-0.2-0.3-0.5-0.9-0.7-2c-0.3-1.4-0.4-3.2-0.4-5.3c0-0.3,0-0.7,0-1.1
    	c0-1.1,0.1-2.2,0.3-3.4c0-0.4,0.1-0.8,0.1-1.1l0-0.1c0-0.3,0.1-0.5,0.1-0.8l0-0.2c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1
    	c0-0.3,0.1-0.6,0.1-0.8l0.1-0.6c0-0.3,0.1-0.6,0.1-0.9l0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1l0.2-1c0-0.1,0-0.2,0.1-0.3l0-0.1
    	c0.1-0.4,0.1-0.8,0.2-1.1l0.1-0.3c0.1-0.3,0.1-0.7,0.2-1l0-0.1c0-0.1,0.1-0.2,0.1-0.4c0.1-0.3,0.1-0.7,0.2-1l0-0.1
    	c0-0.1,0-0.2,0.1-0.3l0-0.1c0-0.1,0-0.1,0-0.2l0.4-1.7c0-0.2,0.1-0.4,0.1-0.6l0.6-2.5c0-0.1,0.1-0.2,0.1-0.3l0-0.1
    	c0.1-0.2,0.1-0.4,0.2-0.7l0-0.1c0.1-0.2,0.1-0.5,0.2-0.7l0.7-2.6l0-0.1c0,0,0-0.1,0-0.1l0.1-0.3c0-0.2,0.1-0.3,0.1-0.5
    	c0-0.1,0.1-0.2,0.1-0.3l0.3-0.9c0-0.1,0-0.1,0.1-0.2l0.3-1c0-0.1,0-0.1,0.1-0.2l0.1-0.2c0.1-0.2,0.1-0.5,0.2-0.7l0.1-0.4
    	c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0.1-0.3,0.1-0.4l0.1-0.3c0.1-0.2,0.1-0.4,0.2-0.6l0.1-0.3c0-0.1,0-0.1,0.1-0.2l0.4-1.2
    	c0.1-0.2,0.1-0.4,0.2-0.6l0.1-0.4c0-0.1,0-0.1,0.1-0.2l0.7-2.3c0.1-0.3,0.2-0.6,0.3-0.9l0.3-1.1c0-0.1,0.1-0.2,0.1-0.2l0.2-0.5h0
    	l0.3-1c0.3-1,0.7-2,1-3.1l0.6-0.4l-0.3-0.5l0.1-0.3c0.1-0.2,0.1-0.4,0.2-0.7c0-0.1,0.1-0.3,0.1-0.4l0.1-0.2c0,0,0,0,0-0.1l1-2.5
    	c0-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.2,0.2-0.4,0.2-0.6c0-0.1,0.1-0.2,0.2-0.3c0.1-0.2,0.2-0.4,0.3-0.6l0,0
    	c0-0.1,0-0.1,0.1-0.1l0.1-0.2c0.1-0.2,0.2-0.4,0.3-0.6l0.2-0.3c0-0.1,0.1-0.2,0.2-0.3c0.5,0,1.3,0.1,2.5,0.3l0.2,0
    	c3.9,0.9,8.9,1.7,14.6,2.5c3.5,0.5,7.2,0.9,10.9,1.1c2.8,0.2,5,0.4,6.8,0.4c0.1,0,0.1,0,0.2,0l0.1,0c1,0.1,1.8,0.2,2.3,0.2
    	c0,0.1,0,0.3,0,0.5v1.2v0.1l0,1.4l0,0c0,0.2,0,0.5,0,0.7c-0.3,1.9-0.6,4.1-1,6.4c-0.5,3.1-0.9,6.2-1.3,8.5c-9,1.6-15,6.4-15,12
    	c0,3.5,2.3,6.7,6.5,9.1c4,2.3,9.3,3.5,14.9,3.5c5.6,0,10.9-1.2,14.9-3.5c4.2-2.4,6.5-5.6,6.5-9.1c0-5.3-5.6-10-13.9-11.8
    	c-0.1-0.8-0.3-1.8-0.5-2.8c-0.8-4.3-1.8-10.1-2.2-14.7c0-0.3,0-0.5,0-0.7c0-0.4,0-0.7,0-0.9c0.1,0,0.3-0.1,0.6-0.1
    	c3.5,0,8.6-0.4,14.3-1.1c7.3-0.8,14.2-1.8,19.3-3l0.3-0.1c1-0.2,1.9-0.2,2.4-0.3l0.3,0.6c0.3,0.5,0.6,1.2,0.9,1.9
    	c0.4,0.8,0.7,1.7,1.2,2.8l0.1,0.3c0.1,0.3,0.2,0.6,0.4,0.9l0.2,0.5l0,0c0,0,0,0,0,0.1l0,0.1c0.1,0.2,0.1,0.4,0.2,0.6
    	c0,0.1,0.1,0.2,0.1,0.3l0.1,0.3c0.1,0.2,0.1,0.3,0.2,0.5l0.6,1.9c0,0.1,0.1,0.2,0.1,0.4l0.1,0.2c0.1,0.2,0.1,0.4,0.2,0.6l0.2,0.7
    	c0.1,0.2,0.1,0.4,0.2,0.5l0.1,0.2c0.1,0.2,0.1,0.4,0.2,0.6l0.2,0.6c0,0.1,0.1,0.2,0.1,0.3l0,0.1c0,0.1,0.1,0.3,0.1,0.5
    	c0.1,0.2,0.1,0.4,0.2,0.5l0,0.1c0,0.1,0.1,0.2,0.1,0.2l0,0c0,0.2,0.1,0.4,0.2,0.5l0.3,1c0,0.1,0.1,0.2,0.1,0.4l0,0.1
    	c0,0.2,0.1,0.3,0.1,0.5l0.7,2.4c0.1,0.2,0.1,0.4,0.2,0.6c0,0.1,0,0.2,0.1,0.3l0.4,1.3c0,0,0,0.1,0,0.1l0,0.2
    	c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.1,0.5,0.2,0.7l0,0.1c0.1,0.2,0.1,0.5,0.2,0.7c0.1,0.2,0.1,0.5,0.2,0.7l0.9,3.6
    	c0,0.1,0,0.1,0,0.1l0.6,2.3c0,0.1,0,0.2,0.1,0.2c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0,0.2,0.1,0.3l0.2,0.6c0,0.2,0.1,0.3,0.1,0.4l0.4,1.8
    	c0,0,0,0.1,0,0.1l0.1,0.6c0,0.1,0,0.2,0.1,0.3l0.2,1.1c0,0.1,0,0.2,0.1,0.3l0,0c0,0.1,0,0.2,0.1,0.2c0,0.1,0,0.2,0,0.2l0.1,0.6
    	c0,0.1,0,0.2,0.1,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0.1,0.3l0.2,1.3c0,0.1,0,0.3,0.1,0.4l0,0.2c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0.1
    	l0.1,0.4c0,0.2,0,0.3,0.1,0.4l0.1,0.5c0,0.1,0,0.2,0.1,0.3l0.1,0.6c0,0.1,0,0.1,0,0.1l0.1,0.6c0,0.1,0,0.3,0.1,0.4l0,0.3
    	c0,0,0,0.1,0,0.1c0,0.3,0.1,0.6,0.1,0.8l0,0.1v0c0,0.4,0.1,0.8,0.1,1.2c0.2,2.1,0.2,4.1,0.2,5.8c0,0.6-0.1,1.2-0.1,1.7
    	c0,0.1,0,0.2,0,0.2l0,0.1c-0.1,0.7-0.2,1.4-0.4,1.8l-0.1,0.2c-0.1,0.2-0.1,0.3-0.2,0.5c-0.2,0.4-0.3,0.5-0.4,0.6
    	c-0.2,0.1-0.3,0.3-0.3,0.4c-0.1,0.2-0.2,0.4-0.3,0.6c0,0.1-0.1,0.2-0.1,0.3c-0.3,0.9-0.3,2.1-0.2,3.3l0,0.3c0.1,0.4,0.1,0.7,0.2,1.1
    	l0.1,0.2l0,0.1c0.1,0.4,0.4,0.7,0.7,1c0,0,0.1,0.1,0.2,0.1c0.2,0.1,0.3,0.2,0.5,0.3c0.1,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.1,0.3,0.1
    	l0.1,0c0.1,0.1,0.3,0.1,0.5,0.2l0.1,0c0.2,0.1,0.4,0.1,0.7,0.2c0.1,0,0.3,0.1,0.4,0.1c0.2,0,0.5,0.1,0.8,0.1c0.3,0,0.5,0,0.8,0.1
    	c0.1,0,0.2,0,0.4,0h0.1c0.5,0,1.1,0,1.7-0.1l0.1,0c0.1,0,0.2,0,0.2,0c1.1-0.1,2.3-0.3,3.5-0.5c0.5-0.1,1.1-0.2,1.7-0.4
    	c0.9-0.2,1.8-0.5,2.7-0.8l0.6-0.2c0.3-0.1,0.6-0.2,0.9-0.4c0.4-0.2,0.9-0.4,1.4-0.6c0.1-0.1,0.3-0.2,0.4-0.2l0.5-0.3
    	c0.6-0.4,1.2-0.7,1.7-1.1l0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.2c0.2-0.2,0.4-0.4,0.6-0.5c0.2-0.2,0.3-0.3,0.5-0.5c0.3-0.3,0.5-0.7,0.6-1
    	l0.1,0l0.1-0.4c0.1-0.2,0.1-0.5,0.1-0.7c0-0.2,0-0.4-0.1-0.5l0-0.2l-0.1-0.1c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.3-0.2-0.5-0.3-0.8
    	c-0.1-0.1-0.1-0.3-0.2-0.4c-0.2-0.5-0.5-1-0.8-1.4l0-0.1c-0.4-0.6-1.1-1.4-2-1.8c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.2-0.1-0.4-0.2
    	c-0.1-0.1-0.3-0.2-0.5-0.4c0-0.1-0.1-0.1-0.2-0.1c-0.1-0.1-0.2-0.2-0.3-0.3l-0.3-0.3c-0.3-0.3-0.6-0.6-0.9-1l0-0.1
    	c-0.2-0.2-0.3-0.4-0.5-0.6L319,481c-0.2-0.2-0.3-0.5-0.5-0.7c-0.2-0.3-0.3-0.6-0.5-0.8l-0.5-0.9c-0.2-0.3-0.3-0.6-0.5-0.9
    	c-0.7-1.3-1.4-2.7-2-4.2c-0.1-0.2-0.2-0.5-0.3-0.7c0-0.1,0-0.1-0.1-0.1l0-0.1c0,0,0,0,0-0.1l-0.3-0.8c0-0.1-0.1-0.2-0.1-0.3
    	l-0.1-0.3c0-0.1-0.1-0.2-0.1-0.3l-0.1-0.4c0,0,0-0.1,0-0.1l-0.3-1c0-0.1-0.1-0.2-0.1-0.4l-0.2-0.5c0,0,0-0.1,0-0.1l-0.1-0.3
    	c-0.1-0.2-0.1-0.4-0.2-0.6l0-0.1c0-0.1-0.1-0.3-0.1-0.4l-0.2-0.6c0-0.1-0.1-0.3-0.1-0.4l0-0.1c0-0.2-0.1-0.3-0.1-0.5c0,0,0,0,0-0.1
    	l0-0.1c0-0.1-0.1-0.2-0.1-0.4l-1-3.3c0-0.1-0.1-0.2-0.1-0.4l-0.1-0.3c0-0.2-0.1-0.3-0.1-0.4l-0.3-1.1c-0.1-0.2-0.1-0.5-0.2-0.8
    	l-0.1-0.4c-0.1-0.3-0.1-0.5-0.2-0.8c0-0.1-0.1-0.3-0.1-0.4l-0.3-1.1c-0.1-0.3-0.2-0.5-0.2-0.8l0-0.1c0-0.1-0.1-0.2-0.1-0.3l-0.6-2.3
    	c-0.1-0.3-0.2-0.6-0.2-0.9l-0.1-0.3c-0.1-0.3-0.1-0.6-0.2-0.9l-0.4-1.5c-0.1-0.3-0.1-0.6-0.2-0.8l0-0.1c0-0.1,0-0.1-0.1-0.2
    	l-0.2-0.9c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.3-0.1-0.6-0.2-0.9c0-0.1,0-0.2-0.1-0.3l-0.1-0.3c-0.1-0.2-0.1-0.4-0.1-0.6l-0.3-1.1l0-0.1
    	c0,0,0-0.1,0-0.1l-0.5-2.3c-0.1-0.2-0.1-0.5-0.2-0.8l-0.2-0.8c-0.2-1.1-0.5-2.4-0.7-3.6l0-0.2c0-0.1,0-0.3-0.1-0.4l-0.3-1.5
    	c0-0.2,0-0.3-0.1-0.4l-0.1-0.5c0-0.2-0.1-0.4-0.1-0.5l-0.1-0.5c0-0.2,0-0.4-0.1-0.6l0-0.1c0-0.1,0-0.1,0-0.2l0-0.1
    	c0-0.2,0-0.4-0.1-0.5l0.1-0.2l-0.1-0.2c0-0.2,0-0.4-0.1-0.5l0-0.1c0,0,0-0.1,0-0.1v-0.1c0-0.1,0-0.2,0-0.4l0-0.3c0,0,0-0.1,0-0.1
    	l-1.1,0.1l1.1-0.2c0-0.2,0-0.4,0-0.6l0-0.3V427c0-0.1,0-0.2,0-0.3c0.4-0.2,1-0.5,2.3-1l0.1,0c3.9-1.2,8.6-3,13.8-5.2
    	c3.4-1.4,6.7-2.9,10-4.5c2.4-1.1,4.5-2.2,6.1-3c0.1-0.1,0.2-0.1,0.3-0.2c0.9-0.4,1.6-0.7,2.1-0.9c0.1,0.1,0.1,0.2,0.2,0.4l1.1,2l0,0
    	c0.2,0.4,0.4,0.7,0.6,1.1c0.7,1.7,11.5,21.2,12.4,23.3c1.1,2.9,2.3,5.9,3.2,8.1c-7,5.9-9.8,13-7,17.9c3.5,6.1,14.4,6.2,24.8,0.2
    	c10.4-6,15.8-15.5,12.3-21.6c-2.7-4.6-9.8-5.9-18-3.2c-0.6-0.7-1.2-1.4-1.9-2.2c-2.8-3.3-16.7-25.2-19.3-28.9
    	c-0.1-0.2-0.2-0.4-0.4-0.6c-0.2-0.4-0.3-0.6-0.4-0.8c0.1-0.1,0.3-0.2,0.4-0.4c3-1.8,7.1-4.6,11.7-8c6-4.4,11.5-8.8,15.4-12.4
    	l0.3-0.2c1.1-0.8,1.6-1.2,1.9-1.4l0.6,0.4c0.6,0.3,1.2,0.8,1.8,1.2c0.7,0.5,1.5,1.1,2.4,1.8c0.3,0.3,0.7,0.5,1,0.8l1.4,1.1l0,0
    	l0.1,0.1c0.2,0.1,0.3,0.3,0.5,0.4c0.1,0.1,0.2,0.2,0.4,0.3l0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3l0.5,0.5c0.2,0.1,0.3,0.3,0.5,0.4
    	c0.1,0.1,0.2,0.2,0.3,0.3l0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2l0.7,0.7l0,0h0l0,0c0.1,0.1,0.3,0.3,0.5,0.4c0.1,0.1,0.3,0.3,0.4,0.4
    	c0,0,0.1,0.1,0.1,0.1l1.2,1.1c0.1,0.1,0.2,0.2,0.4,0.4c0.1,0.1,0.2,0.2,0.4,0.4c0.1,0.1,0.2,0.2,0.3,0.3l0.6,0.5
    	c0.1,0.1,0.1,0.1,0.2,0.2l0.8,0.7c0.1,0.1,0.2,0.2,0.4,0.4l1.1,1.1c0.1,0.1,0.1,0.1,0.2,0.1l0,0c0.1,0.1,0.3,0.2,0.4,0.4l0.5,0.5
    	c0.1,0.1,0.1,0.1,0.1,0.1l1,1c0,0,0,0,0,0l0.6,0.6c0.1,0.1,0.2,0.2,0.3,0.3l2,2c0.2,0.1,0.3,0.3,0.5,0.5c0.1,0.1,0.3,0.3,0.4,0.4
    	l0.1,0.1c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.2,0.3,0.3l0.7,0.7c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.3,0.3,0.4,0.4l0.4,0.5
    	c0.1,0.1,0.2,0.2,0.2,0.3l0,0c0.1,0.1,0.2,0.2,0.4,0.4l1.1,1.2c0.1,0.1,0.2,0.2,0.3,0.3l0.2,0.2c0,0.1,0.1,0.1,0.1,0.1l0.5,0.5
    	c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.1,0.1,0.2,0.2l0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.4l0.5,0.5c0,0.1,0.1,0.1,0.1,0.1
    	c0.1,0.1,0.2,0.2,0.3,0.4l0,0c0.1,0.1,0.1,0.1,0.2,0.2l0,0c0,0,0.1,0.1,0.1,0.1l0.7,0.8c0,0,0.1,0.1,0.1,0.1l0.6,0.7
    	c0,0.1,0.1,0.1,0.1,0.2c0,0,0.1,0.1,0.1,0.1l1,1.2c0.1,0.1,0.2,0.2,0.2,0.3l0.3,0.4c0,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.2,0.2,0.3,0.3
    	l0.4,0.5c0.2,0.2,0.4,0.5,0.5,0.7l0,0.1c1.2,1.6,2.2,3.1,3.1,4.6c0.2,0.3,0.3,0.6,0.5,0.8c0.3,0.6,0.6,1.1,0.8,1.6
    	c0.3,0.7,0.6,1.4,0.8,2c0.1,0.2,0.1,0.4,0.1,0.5c0,0.2,0.1,0.3,0.1,0.5c0,0.1,0,0.3,0,0.4c0,0.3,0,0.4-0.1,0.5
    	c-0.1,0.4-0.1,0.9,0,1.5c0,0.1,0.1,0.2,0.1,0.3c0.1,0.4,0.3,0.9,0.6,1.4c0.1,0.1,0.1,0.2,0.2,0.4c0.4,0.7,0.9,1.4,1.4,1.9l0.1,0.1
    	l0.1,0.1l0.2,0.2l0.1,0c0.3,0.2,0.6,0.3,1,0.4l0.3,0c0.1,0,0.2,0,0.4,0h0.2h0.1c0.1,0,0.3,0,0.4-0.1c0.2,0,0.4-0.1,0.6-0.1
    	c0.3-0.1,0.7-0.2,1-0.3c0.1,0,0.2-0.1,0.3-0.1l0.1,0c0.7-0.3,1.5-0.7,2.3-1.2l0.1-0.1c0.2-0.1,0.5-0.3,0.7-0.5h0l0.1-0.1
    	c0.2-0.2,0.5-0.3,0.7-0.5c1-0.8,2-1.6,2.9-2.5c0.1-0.1,0.3-0.3,0.4-0.4c0.5-0.5,1-1.1,1.6-1.7c0.2-0.3,0.5-0.6,0.7-0.8
    	c0.2-0.3,0.4-0.6,0.6-0.8l0.1-0.1c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.3,0.4-0.5,0.5-0.8l0.1-0.1
    	c0.1-0.1,0.1-0.2,0.1-0.2c0.1-0.1,0.1-0.2,0.2-0.3l0.2-0.4c0.1-0.1,0.1-0.2,0.2-0.4c0-0.1,0.1-0.2,0.2-0.3l0.1-0.2
    	c0-0.1,0.1-0.2,0.1-0.3l0,0c0,0,0-0.1,0-0.1c0.1-0.2,0.1-0.4,0.2-0.6l0.1-0.4c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.2,0.1-0.3
    	c0.1-0.4,0.1-0.7,0.1-1.1l0-0.1c0-0.1,0-0.1,0-0.2l0-0.1c0-0.1,0-0.2-0.1-0.4c-0.1-0.4-0.3-0.7-0.5-0.9l-0.2-0.3l-0.2-0.1
    	c-0.1-0.1-0.3-0.3-0.4-0.4c0,0-0.1-0.1-0.1-0.1l-0.1-0.1c-0.1-0.1-0.2-0.1-0.2-0.2c-1.4-1.1-3-1.6-4.2-1.6c-0.1,0-0.2,0-0.3,0
    	l-0.1,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3-0.1-0.5-0.1c-0.4-0.1-0.8-0.2-1.3-0.4
    	c-0.1,0-0.2-0.1-0.3-0.1c-0.2-0.1-0.3-0.1-0.5-0.2l-0.1,0c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3-0.1-0.5-0.2-0.8-0.4
    	c-0.4-0.2-0.8-0.4-1.3-0.7c-0.1-0.1-0.2-0.1-0.3-0.2l-0.3-0.2c-0.1,0-0.1-0.1-0.2-0.1l-0.4-0.2c-0.1-0.1-0.3-0.2-0.4-0.2
    	c-1.4-0.8-2.7-1.8-4-2.8l-1-0.8c-0.1-0.1-0.2-0.1-0.2-0.2c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.3-0.2-0.4-0.3
    	l-0.1-0.1c0,0-0.1-0.1-0.1-0.1l-0.4-0.4c0,0,0,0-0.1-0.1l-0.1-0.1c0,0-0.1-0.1-0.1-0.1l-0.3-0.2c-0.1-0.1-0.2-0.1-0.3-0.2l-0.9-0.8
    	c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.2-0.4-0.3l-0.4-0.4c-0.1-0.1-0.2-0.2-0.3-0.3l-0.3-0.2c-0.1-0.1-0.2-0.2-0.2-0.2l-0.5-0.5
    	c-0.1-0.1-0.2-0.2-0.3-0.3l-0.5-0.5c-0.1-0.1-0.2-0.2-0.3-0.3l-2.5-2.3c-0.2-0.2-0.4-0.4-0.6-0.6c-0.1-0.1-0.2-0.2-0.3-0.2l-0.9-0.8
    	c-0.2-0.2-0.4-0.4-0.6-0.6c-0.1-0.1-0.2-0.2-0.2-0.2l-1.5-1.5c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.2-0.4-0.4l-1.1-1.1
    	c0-0.1-0.1-0.1-0.1-0.1l-0.1-0.1c-0.2-0.2-0.4-0.4-0.6-0.6l-0.2-0.2c-0.2-0.2-0.4-0.4-0.7-0.7c-0.1-0.1-0.1-0.1-0.2-0.2l-1.5-1.5
    	c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.1-0.2-0.3-0.4-0.4l-0.5-0.5c-0.2-0.2-0.3-0.4-0.5-0.5l-0.4-0.4c-0.2-0.2-0.4-0.4-0.5-0.6l-0.2-0.2
    	c-0.2-0.2-0.4-0.5-0.7-0.7c0,0-0.1-0.1-0.1-0.1L394,387c-0.1-0.1-0.1-0.1-0.2-0.2l-0.6-0.6c0,0-0.1-0.1-0.1-0.1l0,0l-0.4-0.5
    	c-0.9-1-1.6-1.9-2.3-2.7l-0.2-0.2c-0.2-0.2-0.3-0.3-0.4-0.5l-0.2-0.3c-0.2-0.2-0.4-0.5-0.6-0.7l-0.8-0.9c-0.1-0.1-0.1-0.2-0.2-0.2
    	l-0.9-1.2c-0.1-0.2-0.2-0.3-0.4-0.5l-0.1-0.1c0,0-0.1-0.1-0.1-0.1l0-0.1c-0.1-0.1-0.2-0.2-0.2-0.3l-0.1-0.2c0,0,0-0.1-0.1-0.1l0-0.1
    	c-0.1-0.2-0.3-0.4-0.4-0.6c0,0,0,0,0-0.1l0-0.1c-0.1-0.2-0.2-0.3-0.3-0.5l-0.2-0.3c0,0,0,0,0-0.1c0-0.1-0.1-0.2-0.1-0.3
    	c0.3-0.5,0.8-1.2,1.5-2l0.1-0.1c2.7-2.9,6-6.9,9.4-11.4c2.2-2.9,4.4-5.9,6.4-8.9c1.5-2.2,2.9-4.2,3.8-5.7c0.1-0.1,0.1-0.2,0.2-0.2
    	c0.6-0.9,1-1.5,1.4-1.9c0.1,0.1,0.3,0.1,0.4,0.2l2.5,1.5v0c0.2,0.1,0.3,0.2,0.5,0.3c1.4,1.1,3.1,2.5,4.8,3.8c2.5,2,5.1,4.1,7,5.6
    	c-3.1,8.5-1.9,16.2,2.9,19c3,1.7,6.9,1.4,11.1-1.1c4-2.3,7.7-6.3,10.5-11.1c2.8-4.9,4.4-10.1,4.4-14.6c0-4.8-1.6-8.4-4.6-10.2
    	c-4.6-2.7-11.4-0.2-17.2,6.2c-0.8-0.3-1.7-0.6-2.7-1c-4.1-1.5-9.7-3.5-13.9-5.4c-0.2-0.1-0.3-0.2-0.5-0.3l-0.1-0.1
    	c-0.4-0.2-0.6-0.4-0.8-0.5c0.1-0.1,0.1-0.3,0.2-0.5c1.7-3,4-7.7,6.2-13c3-6.7,5.5-13.2,7.1-18.3l0.1-0.3c0.5-1.2,0.8-1.9,1-2.2
    	l0.7,0c0.7,0,1.6,0.1,2.5,0.2c0.8,0.1,1.6,0.2,2.6,0.3l0.4,0.1c0.3,0.1,0.6,0.1,0.9,0.1l1,0.2l0,0l0.3,0.1c0.2,0,0.4,0.1,0.6,0.1
    	c0.1,0,0.2,0,0.3,0.1l0.3,0.1c0.1,0,0.2,0,0.3,0.1c0.1,0,0.2,0,0.3,0.1l0.6,0.1c0.2,0,0.4,0.1,0.7,0.1c0.2,0,0.4,0.1,0.7,0.1
    	c0.2,0,0.4,0.1,0.7,0.1l1.4,0.3c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0.1l0.3,0.1c0.2,0.1,0.4,0.1,0.6,0.1l0.2,0.1
    	c0.2,0,0.4,0.1,0.5,0.1c0.2,0,0.4,0.1,0.5,0.1l0,0c0.1,0,0.2,0.1,0.3,0.1l1.5,0.3c0.2,0,0.3,0.1,0.5,0.1c0.1,0,0.3,0.1,0.4,0.1
    	c0.1,0,0.2,0,0.3,0.1l0.7,0.2c0.2,0,0.3,0.1,0.5,0.1c0.1,0,0.3,0.1,0.4,0.1l0.6,0.2c0.1,0,0.2,0.1,0.4,0.1l0.2,0.1
    	c0.1,0,0.2,0,0.2,0.1l0.6,0.2c0.2,0.1,0.4,0.1,0.6,0.1c0.1,0,0.2,0.1,0.2,0.1l0.6,0.2c0.2,0.1,0.5,0.1,0.7,0.2c0.1,0,0.1,0,0.2,0.1
    	l1.8,0.5c0.1,0,0.1,0,0.2,0.1l0.8,0.2c0.2,0.1,0.3,0.1,0.4,0.1l1.1,0.3c0.1,0,0.3,0.1,0.5,0.1c0.2,0.1,0.4,0.1,0.6,0.2
    	c0.2,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0.1,0.4,0.1l1,0.3c0.1,0,0.1,0,0.2,0.1l0.1,0c0.1,0,0.2,0.1,0.2,0.1l0.2,0.1c0,0,0.1,0,0.1,0
    	l1.2,0.4c0.1,0,0.3,0.1,0.4,0.1l1,0.3c0.1,0,0.2,0.1,0.3,0.1l0.4,0.1c0.2,0.1,0.5,0.1,0.7,0.2l0.2,0.1c0,0,0.1,0,0.1,0.1l0.4,0.1
    	c0.2,0.1,0.5,0.2,0.7,0.2c0.1,0,0.2,0.1,0.3,0.1l0.5,0.2c0.3,0.1,0.5,0.2,0.8,0.3l0,0c0.2,0.1,0.3,0.1,0.5,0.2l0.1,0l0.4-1.1
    	l-0.3,1.1l0.9,0.3c0.1,0,0.2,0.1,0.2,0.1l0.1,0c0,0,0.1,0,0.1,0l1.4,0.5c0.2,0.1,0.4,0.2,0.6,0.2c0,0,0.1,0,0.1,0.1l0.2,0.1
    	c0.1,0,0.1,0,0.1,0.1l0.8,0.3c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0.1l0.1,0c0.3,0.1,0.7,0.3,1,0.4c0.7,0.3,1.3,0.6,1.8,0.8
    	c4.8,2.3,7.3,4.4,8,5.6c0,0,0,0.1,0.1,0.1c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.2,0.1,0.3
    	c0,0.1,0.1,0.2,0.2,0.4l0,0.1c0.1,0.1,0.1,0.2,0.2,0.2c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0,0,0.1,0.1,0.1,0.1
    	c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0.1,0.3,0.2,0.4,0.4l0,0c0,0,0.1,0.1,0.1,0.1c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.2,0.2,0.4,0.2
    	c0.5,0.3,1.1,0.6,1.7,0.8c0.2,0.1,0.5,0.2,0.7,0.2c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0.2,0.1,0.5,0.1,0.7,0c0.4,0,0.9-0.2,1.3-0.4
    	l0.1,0c0.3-0.2,0.7-0.4,1-0.7c0.2-0.2,0.3-0.3,0.5-0.5c0.3-0.4,0.6-0.8,1-1.3c0.1-0.2,0.3-0.5,0.5-0.7c0.2-0.2,0.3-0.5,0.5-0.8
    	c0.5-0.8,0.9-1.8,1.2-2.7c0.3-0.7,0.5-1.4,0.7-2.1c0.1-0.4,0.2-0.7,0.3-1.1c0.1-0.4,0.2-0.8,0.3-1.1c0.1-0.4,0.2-0.8,0.2-1.1
    	c0.3-1.4,0.4-2.8,0.5-4.1c0-0.7,0-1.3,0-1.8c0-0.3,0-0.5-0.1-0.8c0-0.3-0.1-0.5-0.1-0.8c-0.2-0.8-0.4-1.4-0.7-1.9
    	c-0.1-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.2-0.2-0.3-0.4c-0.2-0.2-0.4-0.3-0.5-0.4c-0.1,0-0.2-0.1-0.2-0.1l0-0.1l-0.5-0.1
    	c-0.4-0.1-0.7-0.2-1.1-0.2c-0.1,0-0.2,0-0.3,0c-1.9-0.2-3.7,0.1-4.7,0.9l0,0c0,0-0.1,0-0.1,0.1l0,0c-0.2,0.1-0.6,0.4-1.5,0.6
    	c-0.2,0.1-0.4,0.1-0.6,0.2c-0.7,0.2-1.5,0.3-2.4,0.3c-0.3,0-0.6,0-0.9,0.1c-0.3,0-0.7,0-1,0c-0.6,0-1.4,0-2.1,0
    	c-0.8,0-1.7-0.1-2.6-0.2c-0.7-0.1-1.4-0.1-2-0.2c-0.3,0-0.7-0.1-1.1-0.2c-0.1,0-0.2,0-0.3,0c-0.3,0-0.6-0.1-0.9-0.1
    	c-0.1,0-0.1,0-0.2,0l-0.4-0.1c-0.2,0-0.5-0.1-0.7-0.1c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c-0.3-0.1-0.6-0.1-1-0.2
    	c-0.1,0-0.2,0-0.3-0.1c-0.4-0.1-0.8-0.2-1.2-0.2l-0.3-0.1c-0.3-0.1-0.7-0.1-1-0.2c0,0-0.1,0-0.1,0l-0.1,0c-0.1,0-0.2,0-0.3-0.1
    	c-0.3-0.1-0.7-0.1-1.1-0.2c-0.1,0-0.2,0-0.3-0.1c0,0-0.1,0-0.1,0l-0.4-0.1c-0.3-0.1-0.6-0.1-0.9-0.2l-0.2,0c-0.1,0-0.3-0.1-0.4-0.1
    	c-0.3-0.1-0.6-0.1-0.9-0.2l-0.2,0c-0.1,0-0.3-0.1-0.4-0.1l-1.2-0.3c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.2-0.1-0.4-0.1l-0.1,0
    	c-0.2-0.1-0.4-0.1-0.7-0.2l-0.3-0.1c-0.2,0-0.3-0.1-0.5-0.1l-0.2-0.1c-0.1,0-0.3-0.1-0.5-0.1c-0.2,0-0.4-0.1-0.5-0.1l-0.4-0.1
    	c-0.1,0-0.3-0.1-0.4-0.1l-0.2-0.1c-0.1,0-0.2-0.1-0.3-0.1l-0.1,0c0,0-0.1,0-0.1,0l-0.3-0.1c-0.2-0.1-0.3-0.1-0.5-0.1
    	c-0.1,0-0.2-0.1-0.3-0.1l-0.9-0.3c-0.1,0-0.3-0.1-0.3-0.1l-2.3-0.7c-0.3-0.1-0.6-0.2-0.9-0.2l-0.6-0.2c-0.2-0.1-0.4-0.1-0.6-0.2
    	c-0.1,0-0.2,0-0.2-0.1l-1.1-0.4c-0.3-0.1-0.5-0.1-0.8-0.2l-0.3-0.1c-0.3-0.1-0.6-0.2-0.9-0.3l-0.2-0.1c-0.2-0.1-0.4-0.1-0.6-0.2
    	l-0.5-0.2c-0.1-0.1-0.3-0.1-0.5-0.1c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.1l-1.1-0.3c-0.2-0.1-0.3-0.1-0.5-0.2v0l-1-0.3
    	c-1.2-0.4-2.4-0.8-3.4-1.2l-0.8-0.3c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.1l-0.1,0c-0.1-0.1-0.3-0.1-0.4-0.2l-0.2-0.1
    	c-0.2-0.1-0.3-0.1-0.5-0.2l-0.5-0.2c-0.1,0-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2l-0.2-0.1c-0.1,0-0.2-0.1-0.3-0.1
    	c0,0-0.1,0-0.1,0c-0.1-0.1-0.2-0.1-0.3-0.1l-0.5-0.2c-0.2-0.1-0.4-0.2-0.6-0.2c-0.1-0.1-0.2-0.1-0.3-0.1l0,0c0,0-0.1-0.1-0.1-0.1
    	l-0.4-0.2c0,0-0.1,0-0.1-0.1l-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.3l-0.3-0.2c-0.1,0-0.2-0.1-0.3-0.2c0-0.5,0.1-1.4,0.3-2.5l0-0.1
    	c0.9-3.9,1.7-8.9,2.5-14.6c0.5-3.5,0.9-7.2,1.1-10.9c0.2-2.7,0.4-5,0.4-6.8c0-0.1,0-0.1,0-0.2l0-0.1c0.1-1,0.2-1.8,0.3-2.3
    	c0.1,0,0.3,0,0.5,0h1.2h0.1l1.4,0l0,0c0.2,0,0.5,0,0.7,0c1.8,0.3,4,0.6,6.2,0.9c3.1,0.5,6.3,1,8.7,1.3c1.6,9,6.4,15,12,15
    	c3.5,0,6.7-2.3,9.1-6.5c2.3-4,3.5-9.3,3.5-14.9c0-5.6-1.2-10.9-3.5-14.9c-2.4-4.2-5.6-6.5-9.1-6.5c-5.3,0-10,5.6-11.8,13.9
    	c-0.8,0.1-1.8,0.3-2.8,0.5c-4.3,0.8-10.1,1.9-14.7,2.2c-0.2,0-0.5,0-0.7,0c-0.4,0-0.7,0-0.9,0c0-0.1,0-0.3-0.1-0.6
    	c0-3.5-0.4-8.6-1.1-14.3c-0.8-7.2-1.9-14.2-3-19.3l0-0.1c0-0.1,0-0.1,0-0.2l0-0.1c-0.2-1.3-0.3-2-0.3-2.4l0.6-0.3
    	c0.5-0.3,1.2-0.6,1.9-0.9c0.8-0.3,1.7-0.7,2.8-1.2l0.2-0.1c0.3-0.1,0.6-0.2,1-0.4l0.6-0.2l0,0c0,0,0,0,0,0l0.1,0
    	c0.2-0.1,0.3-0.1,0.5-0.2c0.2-0.1,0.4-0.1,0.5-0.2c0.1,0,0.2-0.1,0.3-0.1l1.5-0.5c0.1,0,0.3-0.1,0.4-0.1l0.2-0.1
    	c0.1,0,0.2-0.1,0.4-0.1l0.3-0.1c0.2-0.1,0.4-0.1,0.7-0.2c0.2-0.1,0.5-0.1,0.7-0.2l0.3-0.1c0.1,0,0.2-0.1,0.3-0.1l0.1,0
    	c0.2-0.1,0.4-0.1,0.6-0.2l1.9-0.6c0.1,0,0.2-0.1,0.4-0.1l3.8-1.2c0.1,0,0.3-0.1,0.4-0.1l0.7-0.2c0.1,0,0.3-0.1,0.5-0.1l0.9-0.2
    	c0.1,0,0.2-0.1,0.3-0.1l0.2-0.1c0,0,0.1,0,0.2,0l0.8-0.2c0.2-0.1,0.4-0.1,0.7-0.2l2.3-0.6c0.2-0.1,0.4-0.1,0.6-0.2l1.6-0.4
    	c0.2-0.1,0.4-0.1,0.6-0.1l1.6-0.4c0.1,0,0.1,0,0.2-0.1l0.3-0.1c0.1,0,0.2-0.1,0.3-0.1l0.2-0.1c0.1,0,0.2,0,0.3-0.1l0.2,0
    	c0.1,0,0.2,0,0.3-0.1l2.1-0.5c0.1,0,0.2-0.1,0.3-0.1l0.2,0c0.1,0,0.1,0,0.2,0l1-0.2c0.1,0,0.1,0,0.2,0l1.2-0.3c0.1,0,0.1,0,0.2,0
    	l0.1,0c0.1,0,0.1,0,0.2,0l0.5-0.1c0.1,0,0.1,0,0.2,0l0.6-0.1c0.2,0,0.3-0.1,0.5-0.1l0.3,0c0.1,0,0.1,0,0.2,0l0.4-0.1
    	c0.2,0,0.4-0.1,0.5-0.1c0.1,0,0.1,0,0.2,0c0,0,0,0,0.1,0l0.5-0.1c0.2,0,0.4-0.1,0.5-0.1c0.1,0,0.1,0,0.2,0l0.6-0.1
    	c0.1,0,0.2,0,0.3-0.1l0.2,0.1l0.2-0.1c0.1,0,0.1,0,0.2,0l0,0c0.1,0,0.3,0,0.5-0.1l0.4-0.1l0,0c0,0,0.1,0,0.1,0l0,0
    	c0.3,0,0.7-0.1,1-0.1c0.6-0.1,1.2-0.1,1.8-0.2c0.8-0.1,1.5-0.1,2.2-0.1c0.3,0,0.6,0,0.9,0c2.2,0,4,0.1,5.3,0.5c0,0,0.1,0,0.1,0
    	c0.4,0.1,0.7,0.2,1,0.3l0.1,0c0.2,0.1,0.4,0.2,0.5,0.3c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0.1,0.3,0.3,0.5,0.4c0.2,0.1,0.3,0.2,0.5,0.2
    	c0,0,0.1,0,0.2,0.1l0,0l0.5,0.1c0.3,0.1,0.5,0.1,0.8,0.1c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0l0.2,0c0.1,0,0.2,0,0.2,0
    	c0.2,0,0.4,0,0.7,0l0.1,0c0.1,0,0.1,0,0.2,0c0.6,0,1.2-0.1,1.8-0.3l0.2,0l0.2-0.1c0.4-0.1,0.7-0.4,1-0.7l0.8-0.5l-0.2-0.4
    	c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.1-0.3,0.2-0.4c0,0,0-0.1,0-0.1c0-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.1-0.4,0.2-0.7
    	c0-0.1,0.1-0.3,0.1-0.4c0.1-0.5,0.1-1,0.2-1.6c0-0.1,0-0.2,0-0.4v-0.1c0-0.6,0-1.2-0.1-1.9c0-0.5-0.1-1-0.2-1.5
    	c-0.1-0.7-0.2-1.4-0.4-2.1c-0.1-0.5-0.2-1.1-0.4-1.7c-0.3-0.9-0.5-1.8-0.8-2.7c-0.1-0.3-0.3-0.7-0.4-1c-0.1-0.2-0.1-0.3-0.2-0.5
    	c-0.1-0.3-0.3-0.6-0.4-0.9c-0.1-0.1-0.1-0.3-0.2-0.4l-0.6-1c-0.4-0.6-0.7-1.2-1.1-1.7l-0.2-0.3c-0.1-0.2-0.3-0.3-0.4-0.5
    	c-0.3-0.3-0.6-0.6-0.9-0.8c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.1-0.4-0.2l0-0.1l-0.5-0.1c-0.2-0.1-0.5-0.1-0.7-0.1
    	c-0.2,0-0.5,0-0.7,0.1l0,0c-0.1,0-0.3,0.1-0.4,0.1c-0.3,0.1-0.5,0.2-0.8,0.3l0,0c-0.1,0.1-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.1-0.3,0.2
    	c-0.8,0.4-1.4,0.9-1.9,1.4l0,0c-0.1,0.1-0.1,0.2-0.2,0.2c-0.3,0.3-0.5,0.6-0.6,0.9c0,0,0,0.1-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2
    	c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.2-0.2,0.4-0.4,0.5l-0.1,0.1c0,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.1,0.1-0.2,0.2l0,0
    	c-0.1,0.1-0.3,0.3-0.5,0.4c-0.1,0.1-0.2,0.2-0.2,0.2l-0.3,0.2c-0.2,0.2-0.4,0.3-0.6,0.5l-0.1,0.1c-0.5,0.4-1.1,0.8-1.8,1.2l-0.1,0
    	c-0.4,0.2-0.8,0.5-1.3,0.8c-0.3,0.2-0.6,0.3-0.9,0.5c-1.3,0.7-2.7,1.4-4.2,2c-0.2,0.1-0.4,0.2-0.6,0.3l-0.1,0c0,0-0.1,0-0.1,0.1
    	l-0.1,0c0,0-0.1,0-0.1,0l-0.9,0.3c-0.1,0.1-0.3,0.1-0.4,0.2l-0.1,0c0,0-0.1,0-0.1,0l0,0c0,0-0.1,0-0.2,0.1l-0.7,0.3
    	c-0.1,0-0.1,0.1-0.2,0.1l-0.1,0c-0.2,0.1-0.3,0.1-0.5,0.2c-0.1,0-0.2,0.1-0.4,0.1l-0.5,0.2c0,0-0.1,0-0.1,0c-0.1,0-0.2,0.1-0.3,0.1
    	c-0.1,0-0.2,0.1-0.3,0.1l-0.1,0l-0.3,0.2l-0.3,0.1c-0.2,0.1-0.3,0.1-0.5,0.2l-0.5,0.2c-0.2,0.1-0.4,0.1-0.6,0.2l-0.2,0.1
    	c-0.1,0-0.2,0.1-0.2,0.1l-1.1,0.3c-0.2,0.1-0.4,0.1-0.7,0.2c-0.1,0-0.2,0.1-0.4,0.1l-0.7,0.2c-0.1,0-0.1,0-0.2,0.1l-0.2,0.1
    	c-0.2,0.1-0.5,0.1-0.7,0.2l0,0c-0.1,0-0.2,0.1-0.2,0.1l-0.1,0c-0.2,0.1-0.4,0.1-0.6,0.2l-0.5,0.1c-0.3,0.1-0.5,0.1-0.8,0.2l-0.5,0.2
    	c-0.2,0.1-0.4,0.1-0.6,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.3,0.1-0.5,0.1-0.8,0.2c-0.1,0-0.3,0.1-0.4,0.1c-0.3,0.1-0.5,0.2-0.8,0.2
    	l-0.1,0c-0.1,0-0.2,0-0.3,0.1l-3.9,1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.3,0.1-0.6,0.1-0.9,0.2l-0.3,0.1
    	c-0.3,0.1-0.6,0.1-0.9,0.2l-1.2,0.3c-0.1,0-0.2,0-0.2,0.1l-0.4,0.1c-0.2,0-0.4,0.1-0.5,0.1l-0.2,0.1c-0.3,0.1-0.6,0.1-0.9,0.2
    	l-0.2,0c-0.3,0.1-0.6,0.1-0.9,0.2l-0.2,0c-0.3,0.1-0.6,0.1-0.9,0.2l-0.2,0.1c-0.3,0.1-0.6,0.1-0.9,0.2c-0.1,0-0.2,0-0.3,0.1
    	l-0.3,0.1c-0.1,0-0.3,0.1-0.4,0.1l-0.8,0.2c-1.2,0.2-2.4,0.5-3.6,0.7l-0.3,0.1c-0.2,0-0.4,0.1-0.5,0.1c-0.1,0-0.1,0-0.2,0l-0.1,0
    	c-0.3,0-0.6,0.1-0.9,0.1l-0.5,0.1c-0.2,0-0.3,0-0.5,0.1c-0.2,0-0.4,0.1-0.6,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.2,0-0.4,0.1-0.6,0.1
    	c-0.1,0-0.1,0-0.2,0l-0.1,0c-0.2,0-0.3,0-0.5,0.1l-0.3,0c-0.1,0-0.1,0-0.2,0l-0.8,0.1c-0.2,0-0.4,0-0.7,0.1l-0.2,0
    	c-0.2,0-0.4,0-0.5,0l-0.4,0l-0.1,0c-0.1,0-0.2,0-0.3,0c-0.2-0.4-0.5-1-1-2.3l-0.1-0.1c-1.2-3.9-3-8.6-5.1-13.8
    	c-1.4-3.4-2.9-6.7-4.5-10c-1.1-2.4-2.2-4.5-3-6.1c-0.1-0.1-0.1-0.2-0.2-0.3c-0.4-0.9-0.7-1.6-0.9-2.1c0.1-0.1,0.2-0.1,0.4-0.2
    	c0,0,4.3-1.8,6.4-2.6c2.9-1.1,5.9-2.3,8.1-3.2c5.9,7,13,9.8,17.9,7c6.1-3.5,6.2-14.4,0.2-24.8c-6-10.4-15.5-15.8-21.6-12.3
    	c-4.6,2.6-5.9,9.8-3.2,18c-0.7,0.6-1.4,1.2-2.2,1.9c-3.3,2.8-10.2,8.5-10.4,8.6c-0.1-0.1-0.2-0.3-0.3-0.4c-1.8-3-4.6-7.1-8-11.7
    	c-4.3-5.9-8.8-11.5-12.4-15.4l-0.2-0.2c-0.8-1-1.2-1.6-1.4-1.9l0.4-0.6c0.4-0.6,0.8-1.2,1.2-1.8c0.5-0.7,1.1-1.5,1.8-2.4
    	c0.3-0.3,0.5-0.6,0.8-1l1.1-1.3l0,0l0.7-0.8c0.1-0.1,0.2-0.2,0.3-0.3l0.9-1c0.2-0.2,0.3-0.3,0.5-0.5l0.4-0.5
    	c0.2-0.2,0.3-0.3,0.5-0.5l0.5-0.5c0,0,0-0.1,0.1-0.1l0.6-0.6c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.1-0.1,0.2-0.2
    	c0.1-0.1,0.3-0.3,0.4-0.4c0.1-0.1,0.3-0.3,0.4-0.4l0.7-0.8c0.1-0.1,0.2-0.2,0.2-0.2l0.7-0.8c0.1-0.1,0.2-0.2,0.3-0.3l0.3-0.3
    	c0.1-0.1,0.2-0.2,0.4-0.4l0.2-0.2c0.1-0.1,0.1-0.1,0.2-0.2l0.3-0.3c0.1-0.1,0.3-0.3,0.4-0.4l2.2-2.3c0.1-0.1,0.3-0.3,0.4-0.4
    	l0.1-0.1c0.1-0.2,0.3-0.3,0.4-0.4l0.6-0.6c0.2-0.2,0.3-0.4,0.5-0.5l0.6-0.6c0.2-0.2,0.3-0.3,0.5-0.5c0.2-0.2,0.4-0.4,0.6-0.6
    	l0.4-0.4c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1l0.5-0.5c0.1-0.1,0.2-0.2,0.2-0.2l0,0c0.1-0.1,0.1-0.1,0.2-0.2l0.1-0.1
    	c0.1-0.1,0.1-0.1,0.2-0.2l0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.2,0.3-0.2
    	l0.8-0.8c0.1-0.1,0.2-0.2,0.3-0.3l1-0.9c0.1,0,0.1-0.1,0.1-0.1l0.4-0.4c0,0,0.1-0.1,0.1-0.1l1.4-1.2c0.1-0.1,0.1-0.1,0.2-0.2
    	l0.4-0.4c0.1-0.1,0.1-0.1,0.2-0.2l1.4-1.2c0.1-0.1,0.2-0.1,0.2-0.2l0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1-0.1,0.1-0.1l1.7-1.4
    	c0,0,0.1,0,0.1-0.1l0.1,0c0.1-0.1,0.2-0.2,0.3-0.2l0.9-0.7c0.2-0.2,0.5-0.4,0.7-0.5l0.1,0c1.6-1.2,3.1-2.2,4.6-3.1
    	c2.4-1.4,4.5-2.2,5.8-2.3c0.4,0,0.6,0,0.7,0c0.3,0.1,0.6,0.1,0.9,0.1c0.1,0,0.2,0,0.3,0c0.3,0,0.5-0.1,0.9-0.2
    	c0.3-0.1,0.5-0.2,0.8-0.3l0.1,0c0.1,0,0.2-0.1,0.2-0.1c0.1-0.1,0.2-0.1,0.4-0.2c0.2-0.1,0.5-0.3,0.7-0.4c0.1-0.1,0.3-0.2,0.4-0.3
    	c0.2-0.2,0.5-0.4,0.6-0.5c0,0,0.1-0.1,0.1-0.1l0.1-0.1c0,0,0,0,0.1-0.1l0.3-0.2l0-0.2c0.2-0.3,0.4-0.7,0.5-1.1
    	c0-0.2,0.1-0.4,0.1-0.7c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2l0-0.2c0-0.1-0.1-0.2-0.1-0.3
    	c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.3-0.2-0.4c-0.1-0.2-0.2-0.5-0.3-0.8c-0.1-0.2-0.2-0.5-0.4-0.8l-0.2-0.4l-0.5-0.8
    	c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.3-0.4-0.5-0.6-0.8c-0.3-0.4-0.7-0.9-1-1.2c-0.6-0.7-1.2-1.4-1.9-2.1
    	c-0.5-0.5-1.1-1.1-1.7-1.6c-0.3-0.2-0.6-0.5-0.8-0.7c-0.3-0.2-0.6-0.4-0.8-0.6c-0.5-0.4-1.1-0.8-1.7-1.1l-0.1-0.1
    	c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1-0.1-0.2-0.1-0.4-0.2c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.1-0.3-0.1c0,0-0.1,0-0.1-0.1l-0.4-0.2
    	c-0.1,0-0.2-0.1-0.3-0.1l0,0c0,0-0.1,0-0.1,0c-0.2-0.1-0.4-0.2-0.6-0.2l-0.4-0.1c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.2,0-0.2,0l-0.1,0
    	c-0.3-0.1-0.6-0.1-0.9-0.1c-0.2,0-0.5,0-0.7,0.1l-0.2,0c-0.2,0.1-0.4,0.1-0.6,0.2c-0.2,0.1-0.3,0.2-0.5,0.3l-0.3,0.2l0,0.2
    	c-0.1,0.1-0.3,0.3-0.4,0.4c-0.1,0.1-0.2,0.2-0.3,0.3c-0.7,0.9-1.2,1.9-1.5,2.9c-0.1,0.3-0.1,0.5-0.1,0.7c0,0.3,0,0.5,0,0.7l0,0.1
    	c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3l0,0.2c0,0.1,0,0.2,0,0.3c0,0.2-0.1,0.4-0.1,0.6c0,0.1-0.1,0.2-0.1,0.3
    	c-0.1,0.2-0.1,0.4-0.2,0.6c0,0.1-0.1,0.3-0.2,0.5c-0.1,0.2-0.1,0.4-0.2,0.6c0,0.1,0,0.1-0.1,0.2c-0.2,0.5-0.4,1-0.7,1.5
    	c-0.1,0.1-0.1,0.3-0.2,0.4c-0.4,0.7-0.8,1.5-1.3,2.2c-0.7,1.1-1.4,2.2-2.3,3.3l-0.4,0.5c-0.2,0.2-0.3,0.4-0.5,0.6l0,0
    	c0,0-0.1,0.1-0.1,0.1l-0.1,0.2c-0.1,0.1-0.1,0.2-0.2,0.3l-0.2,0.3c-0.1,0.1-0.1,0.1-0.1,0.1l-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.2
    	l-0.5,0.6c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1l-0.2,0.2c-0.1,0.1-0.3,0.3-0.4,0.4l-0.1,0.1c-0.1,0.1-0.1,0.2-0.2,0.2l-0.2,0.2
    	c-0.1,0.1-0.1,0.1-0.2,0.2c0,0-0.1,0.1-0.1,0.1l-0.2,0.3c-0.1,0.1-0.3,0.3-0.4,0.4l-0.3,0.4c-0.1,0.2-0.3,0.3-0.4,0.5l-0.3,0.4
    	c-0.1,0.1-0.2,0.2-0.3,0.4l-0.6,0.6c-0.1,0.1-0.2,0.2-0.2,0.2c-0.1,0.1-0.1,0.1-0.2,0.2l-0.6,0.6c-0.1,0.1-0.2,0.2-0.3,0.3
    	c-0.2,0.2-0.3,0.3-0.5,0.5c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.2-0.3,0.3l-0.5,0.5c-0.2,0.2-0.4,0.4-0.5,0.5l-0.1,0.1
    	c-0.1,0.1-0.1,0.1-0.2,0.2l-2.2,2.3c-0.1,0.1-0.1,0.1-0.2,0.2l-1.7,1.7c0,0-0.1,0.1-0.1,0.1l-1.5,1.5c-0.1,0.1-0.1,0.1-0.2,0.2
    	l-1.7,1.7c-0.1,0.1-0.3,0.2-0.4,0.4l-0.5,0.5c-0.2,0.2-0.4,0.4-0.6,0.6c-0.1,0.1-0.2,0.2-0.2,0.2c-0.2,0.2-0.4,0.4-0.7,0.6l-0.1,0.1
    	c0,0-0.1,0.1-0.1,0.1l-0.7,0.6c0,0-0.1,0.1-0.1,0.1l-1.7,1.5c0,0-0.1,0.1-0.1,0.1l-0.3,0.2c-0.1,0.1-0.3,0.2-0.4,0.4
    	c0,0-0.1,0.1-0.1,0.1l-0.8,0.7l0,0l-0.5,0.4c-1,0.9-1.8,1.6-2.7,2.3l-0.8,0.6c-0.1,0.1-0.2,0.1-0.2,0.2c-0.2,0.2-0.4,0.4-0.7,0.5
    	l-0.4,0.3c-0.1,0.1-0.2,0.1-0.2,0.2l-0.2,0.2c-0.1,0.1-0.3,0.2-0.4,0.3L379,111c-0.2,0.1-0.4,0.3-0.6,0.4l-0.1,0.1
    	c0,0-0.1,0.1-0.1,0.1l-0.1,0c-0.1,0.1-0.2,0.2-0.3,0.2l-0.2,0.1c0,0-0.1,0-0.1,0.1l0,0l-0.1,0c-0.2,0.1-0.4,0.3-0.6,0.4l-0.1,0.1
    	c-0.2,0.1-0.3,0.2-0.5,0.3L376,113c0,0,0,0-0.1,0c-0.1,0-0.2,0.1-0.3,0.1c-0.4-0.3-1.2-0.8-2-1.5l-0.1-0.1c-2.9-2.7-6.9-6-11.4-9.4
    	c-2.9-2.2-5.9-4.4-8.9-6.4c-2.2-1.5-4.2-2.9-5.7-3.8c-0.1-0.1-0.2-0.1-0.3-0.2c-0.9-0.6-1.5-1.1-1.9-1.4c0.1-0.1,0.1-0.3,0.2-0.4
    	l0.7-1.1l0.8-1.3l0,0c0.1-0.2,0.2-0.3,0.3-0.5c1.1-1.4,2.4-3.1,3.8-4.8c2-2.5,4.1-5.1,5.6-7c8.5,3.1,16.2,1.9,19-2.9
    	c1.7-3,1.3-7-1.1-11.1c-2.3-3.9-6.3-7.7-11.1-10.5c-4.9-2.8-10.1-4.4-14.6-4.4c-4.8-0.1-8.4,1.6-10.2,4.6
    	c-2.6,4.6-0.2,11.4,6.2,17.2c-0.3,0.8-0.6,1.7-1,2.7c-1.5,4.1-3.5,9.7-5.4,13.9c-0.1,0.2-0.2,0.3-0.3,0.5l-0.1,0.1
    	c-0.2,0.4-0.4,0.6-0.5,0.8c-0.1-0.1-0.3-0.1-0.5-0.2c-3-1.7-7.7-4-13-6.2c-6.7-3-13.2-5.5-18.3-7.1l-0.3-0.1c-1.2-0.5-1.9-0.8-2.2-1
    	l0-0.7c0-0.7,0.1-1.5,0.2-2.5c0.1-0.8,0.2-1.6,0.3-2.6c0.1-0.4,0.1-0.8,0.2-1.3l0.2-1l0,0l0.3-1.5c0-0.2,0.1-0.4,0.1-0.5l0.2-0.9
    	c0-0.2,0.1-0.3,0.1-0.5l0.1-0.3c0-0.1,0-0.2,0.1-0.3l0.3-1.4c0-0.2,0.1-0.4,0.1-0.7l0,0c0,0,0-0.1,0-0.1l0.6-2.6
    	c0-0.1,0-0.2,0.1-0.3l0.1-0.5c0-0.1,0.1-0.3,0.1-0.4l0-0.2c0-0.1,0.1-0.3,0.1-0.4c0-0.2,0.1-0.3,0.1-0.5l0.4-1.6
    	c0-0.2,0.1-0.3,0.1-0.4c0-0.1,0.1-0.3,0.1-0.4L308,47c0.1-0.2,0.1-0.4,0.2-0.7c0.1-0.2,0.1-0.4,0.2-0.7c0-0.1,0-0.2,0.1-0.2
    	c0-0.1,0.1-0.3,0.1-0.4l0.1-0.2c0.1-0.2,0.1-0.3,0.1-0.5l0.1-0.2c0.1-0.2,0.1-0.4,0.2-0.6l0.8-2.9c0.1-0.2,0.1-0.4,0.2-0.6
    	c0-0.1,0.1-0.2,0.1-0.4l0-0.1c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2,0.1-0.2c0.1-0.2,0.1-0.4,0.2-0.6l0.1-0.5c0-0.1,0.1-0.3,0.1-0.4
    	l0.3-1c0.1-0.1,0.1-0.3,0.1-0.4l0.4-1.2c0,0,0-0.1,0-0.1l0.1-0.4c0,0,0-0.1,0-0.1l0.1-0.3c0.1-0.2,0.1-0.5,0.2-0.7
    	c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0.1-0.2,0.1-0.3l0.3-1c0-0.1,0.1-0.2,0.1-0.3l0.2-0.5c0.1-0.2,0.1-0.4,0.2-0.7l0.1-0.2
    	c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0.1-0.2,0.1-0.2l0.3-0.8c0.1-0.1,0.1-0.3,0.1-0.4l0.5-1.3c0,0,0-0.1,0-0.1l0.1-0.2
    	c0.1-0.2,0.1-0.3,0.2-0.5l0.1-0.2l-1-0.4l1.1,0.3l0.3-0.9c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1l0.1-0.2l0.2-0.4c0.1-0.2,0.2-0.5,0.3-0.7
    	c0.3-0.7,0.6-1.3,0.8-1.8c1-2,1.9-3.7,2.9-5.1c0.1-0.2,0.3-0.4,0.4-0.6c0.3-0.4,0.5-0.7,0.7-0.9c0.1-0.2,0.3-0.3,0.4-0.5
    	c0.9-0.9,1.4-1.1,1.6-1.2c0.2,0,0.4-0.1,0.7-0.3l0,0c0.2-0.1,0.4-0.3,0.6-0.4c0.4-0.3,0.7-0.8,1.1-1.3c0.1-0.2,0.2-0.3,0.3-0.5
    	c0.3-0.5,0.5-1,0.7-1.5c0.1-0.3,0.2-0.5,0.3-0.8c0-0.1,0-0.2,0-0.2c0-0.2,0.1-0.4,0-0.7c0-0.2-0.1-0.5-0.1-0.6
    	c-0.1-0.2-0.1-0.4-0.3-0.6l0-0.1c-0.2-0.4-0.5-0.7-0.9-1.1c-0.3-0.3-0.6-0.6-1-0.9c-0.3-0.3-0.7-0.5-1.1-0.8l-0.1,0
    	c-0.1-0.1-0.2-0.1-0.2-0.1c-0.1,0-0.2-0.1-0.3-0.2l-0.2-0.1c-0.3-0.2-0.7-0.4-1.2-0.6c-0.6-0.3-1.2-0.5-1.9-0.8c-1-0.4-2-0.7-3.2-1
    	c-0.6-0.2-1.2-0.3-1.7-0.4c-0.3-0.1-0.7-0.1-1-0.2c-0.6-0.1-1.1-0.2-1.6-0.2c-0.6-0.1-1.2-0.1-1.8-0.1c-0.1,0-0.3,0-0.4,0l-0.2,0
    	c-0.1,0-0.2,0-0.2,0h-0.3c-0.3,0-0.6,0-0.9,0l-0.2,0c-0.1,0-0.1,0-0.2,0c-0.4,0-0.8,0.1-1.2,0.2c-0.1,0-0.2,0-0.2,0.1l-0.2,0
    	c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0.1-0.3,0.1c-1,0.4-1.7,0.9-2.1,1.7c0,0.1-0.1,0.2-0.2,0.4c-0.1,0.4-0.2,0.8-0.2,1.3
    	c0,0.1,0,0.3,0,0.4c0,0.2,0,0.4,0,0.7c0,0.2,0,0.4,0,0.6c0,0.8,0.2,1.5,0.4,2.1c0,0,0,0.1,0.1,0.1c0.1,0.3,0.3,0.6,0.5,0.8
    	c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.5,0.9,0.7,2c0.1,0.4,0.2,1,0.2,1.5c0.1,1.2,0.2,2.4,0.2,3.8
    	c0,0.5,0,1.1-0.1,1.8c0,0.5-0.1,1-0.1,1.5c-0.1,0.8-0.1,1.6-0.3,2.3c0,0.3-0.1,0.7-0.1,1l0,0.2c0,0.1,0,0.1,0,0.2l0,0.3
    	c0,0.2-0.1,0.4-0.1,0.6l-0.1,0.5c0,0.3-0.1,0.6-0.2,0.9l0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1l-0.2,1c0,0.1,0,0.2-0.1,0.3
    	c-0.1,0.4-0.2,0.8-0.2,1.2l-0.1,0.3c-0.1,0.3-0.1,0.6-0.2,1l0,0.1c0,0,0,0.1,0,0.1l-0.4,1.8c0,0,0,0.1,0,0.1c-0.1,0.3-0.2,0.7-0.2,1
    	l-0.2,0.7c-0.1,0.3-0.1,0.6-0.2,0.9l-0.1,0.6c-0.1,0.4-0.2,0.8-0.3,1.2l-0.1,0.3c0,0.1-0.1,0.2-0.1,0.4l-0.2,1
    	c0,0.2-0.1,0.3-0.1,0.5c0,0.2-0.1,0.3-0.1,0.5l-0.1,0.2c0,0.2-0.1,0.3-0.1,0.4l0,0.1c0,0.1-0.1,0.3-0.1,0.4l-0.3,1
    	c0,0.1,0,0.1-0.1,0.2l-0.2,0.8c0,0.1-0.1,0.3-0.1,0.5l-0.2,0.8c0,0.1,0,0.1,0,0.2l-0.1,0.2c-0.1,0.3-0.1,0.5-0.2,0.8
    	c0,0.1,0,0.2-0.1,0.2l0,0.1c-0.1,0.3-0.1,0.5-0.2,0.8l-0.1,0.3c-0.1,0.3-0.2,0.6-0.2,0.9l-0.1,0.3c-0.1,0.3-0.2,0.6-0.3,0.9
    	c0,0.1,0,0.1-0.1,0.2l-0.4,1.2c-0.1,0.2-0.1,0.4-0.2,0.6l-0.1,0.4c0,0.1,0,0.1-0.1,0.2l-0.7,2.3c-0.1,0.3-0.2,0.5-0.3,0.8l-0.3,1.1
    	c0,0.1-0.1,0.2-0.1,0.2l-0.2,0.5l0,0l-0.3,1c-0.3,1-0.6,1.9-1,3l-0.1,0l-0.1,0.4c-0.1,0.2-0.2,0.4-0.2,0.6l0,0.1
    	c-0.1,0.2-0.2,0.4-0.2,0.6c-0.1,0.2-0.1,0.4-0.2,0.5c0,0,0,0.1,0,0.1l-0.8,1.9c0,0.1-0.1,0.2-0.1,0.3l-0.1,0.2
    	c-0.1,0.1-0.1,0.2-0.2,0.4l-0.1,0.1c0,0.1-0.1,0.2-0.1,0.3l-0.2,0.6c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.2-0.2,0.4-0.3,0.6l0,0
    	c0,0,0,0.1-0.1,0.1l-0.1,0.2c-0.1,0.2-0.2,0.4-0.3,0.6l-0.2,0.4c0,0.1-0.1,0.2-0.2,0.3c-0.5,0-1.3-0.1-2.5-0.3l-0.2,0
    	c-3.9-0.9-8.9-1.7-14.6-2.5c-3.5-0.5-7.2-0.9-10.9-1.1c-2.7-0.2-5-0.4-6.8-0.4c-0.1,0-0.1,0-0.2,0l-0.1,0c-1-0.1-1.8-0.2-2.3-0.3
    	c0-0.1,0-0.3,0-0.5l0-1.2c0-0.1,0-0.1,0-0.2l0-0.9c0-0.4,0-0.8,0-1.2c0.3-1.8,0.6-4,0.9-6.2c0.5-3.1,1-6.3,1.3-8.7
    	c9-1.6,15-6.4,15-12c0-3.5-2.3-6.7-6.5-9.1c-4-2.3-9.3-3.5-14.9-3.5c-5.6,0-10.9,1.2-14.9,3.5c-4.2,2.4-6.5,5.6-6.5,9.1
    	c0,5.3,5.6,10,13.9,11.8c0.1,0.8,0.3,1.8,0.5,2.8c0.8,4.3,1.9,10.1,2.3,14.7c0,0.3,0,0.5,0,0.7c0,0.4,0,0.7,0,0.9
    	c-0.1,0-0.3,0-0.6,0.1c-3.5,0-8.6,0.4-14.3,1.1c-7.2,0.8-14.2,1.9-19.3,3l-0.3,0.1c-1.3,0.2-2,0.3-2.4,0.3l-0.3-0.6
    	c-0.3-0.5-0.6-1.2-0.9-1.9c-0.3-0.8-0.8-1.7-1.2-2.8l-0.1-0.3c-0.1-0.3-0.2-0.6-0.3-0.9l-0.2-0.6l0,0c0,0,0-0.1,0-0.1
    	c-0.1-0.2-0.1-0.4-0.2-0.6c0-0.1-0.1-0.2-0.1-0.3l-0.1-0.3c-0.1-0.2-0.1-0.3-0.2-0.5l-0.9-2.5c-0.1-0.2-0.1-0.4-0.2-0.7
    	c-0.1-0.2-0.1-0.4-0.2-0.7l-0.2-0.8c-0.1-0.2-0.1-0.4-0.2-0.6l-0.3-0.9c0-0.1-0.1-0.2-0.1-0.3l-0.3-1.1c-0.1-0.2-0.1-0.4-0.1-0.5
    	l-0.3-0.9c-0.1-0.2-0.1-0.3-0.1-0.5l-1.5-5.2c-0.1-0.2-0.1-0.4-0.2-0.7l0-0.1c-0.1-0.2-0.1-0.4-0.2-0.7c0,0,0-0.1,0-0.1l-0.5-1.8
    	c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.3-0.1-0.5-0.2-0.8l-0.5-2.1c0,0,0-0.1,0-0.1l0-0.1c-0.1-0.2-0.1-0.5-0.2-0.7c0-0.1-0.1-0.2-0.1-0.4
    	l-0.5-2.1c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.1,0-0.2-0.1-0.3l-0.2-0.9c0-0.1-0.1-0.2-0.1-0.3l-0.4-1.8c0-0.1,0-0.2-0.1-0.3l-0.2-0.7
    	c0-0.1,0-0.1,0-0.2l-0.3-1.2c0-0.1,0-0.1,0-0.2l-0.3-1.8c0-0.2-0.1-0.4-0.1-0.5l0-0.2c0,0,0,0,0,0l-0.1-0.5c0-0.2-0.1-0.4-0.1-0.6
    	l0-0.1c0,0,0,0,0-0.1l0-0.3c0-0.1,0-0.1,0-0.2l0-0.2c0-0.1,0-0.3-0.1-0.4l-0.1-0.7c0-0.2,0-0.3-0.1-0.5l-0.1-0.6
    	c0-0.3-0.1-0.5-0.1-0.7l0-0.2l0,0c-0.1-0.6-0.1-1.2-0.2-1.7c-0.2-3.1-0.2-5.7,0.2-7.7c0-0.3,0.1-0.5,0.2-0.8
    	c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.1-0.4,0.2-0.5c0.2-0.4,0.3-0.6,0.4-0.6c0.1-0.1,0.3-0.3,0.4-0.5c0.1-0.1,0.2-0.3,0.2-0.5
    	c0-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.1-0.5,0.2-0.7c0-0.2,0.1-0.5,0.1-0.8c0-0.1,0-0.2,0-0.4c0-0.4,0-0.7,0-1.1c0-0.6-0.1-1.2-0.3-1.7
    	l0-0.2l-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.2-0.3-0.4-0.6-0.6-0.8c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.1-0.2-0.2l-0.1-0.2l-0.3-0.1
    	c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.2-0.1-0.3-0.1l-0.1,0c-0.2-0.1-0.4-0.1-0.7-0.2c-0.3-0.1-0.6-0.1-0.9-0.2c-0.2,0-0.3-0.1-0.5-0.1
    	c-0.2,0-0.4,0-0.6-0.1c-0.3,0-0.6,0-0.8,0c-0.2,0-0.3,0-0.5,0c-0.1,0-0.2,0-0.3,0h0c-0.1,0-0.2,0-0.4,0c-0.2,0-0.4,0-0.5,0l-0.1,0
    	c-0.1,0-0.3,0-0.4,0c-0.8,0.1-1.5,0.2-2.3,0.3c-0.2,0-0.5,0.1-0.7,0.1c-0.2,0.1-0.5,0.1-0.7,0.2c-0.5,0.1-0.9,0.2-1.3,0.3
    	c-0.7,0.2-1.5,0.4-2.3,0.7c-0.3,0.1-0.6,0.2-0.9,0.3l-0.1,0.1c-0.3,0.1-0.6,0.2-0.8,0.3l-0.1,0.1c-0.3,0.1-0.5,0.2-0.8,0.3l-0.1,0.1
    	c-0.3,0.1-0.5,0.3-0.8,0.4l-0.9,0.5c-0.3,0.2-0.5,0.3-0.7,0.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0.1-0.4,0.3-0.6,0.5
    	c-0.8,0.6-1.3,1.3-1.6,1.9c-0.1,0.1-0.1,0.2-0.2,0.4c0,0.1,0,0.1-0.1,0.2l-0.1,0.2l0,0.2c0,0.2,0,0.4,0,0.6c0,0.1,0,0.3,0.1,0.5
    	l0,0.1c0.3,0.9,0.7,1.8,1.2,2.6c0.1,0.1,0.1,0.2,0.2,0.4c0.4,0.6,0.8,1,1.3,1.4c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.3,0.1,0.4,0.2
    	l0.2,0.1c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.4,0.2,0.5,0.4l0.3,0.3c0.1,0.1,0.2,0.2,0.4,0.4l0.2,0.2c0.1,0.1,0.1,0.1,0.2,0.2l0.3,0.4
    	c0.1,0.1,0.2,0.2,0.2,0.3c0.2,0.2,0.3,0.4,0.5,0.7c0.2,0.2,0.3,0.5,0.5,0.7c0,0,0,0,0,0.1l0.2,0.4c0.2,0.3,0.3,0.6,0.5,0.8
    	c0.1,0.1,0.2,0.3,0.3,0.4c0.2,0.3,0.3,0.6,0.5,0.9c0.2,0.3,0.3,0.6,0.5,1l0,0c0.1,0.2,0.2,0.3,0.2,0.5c0.2,0.3,0.3,0.7,0.5,1
    	c0.3,0.7,0.7,1.5,1,2.2c0.1,0.2,0.2,0.4,0.2,0.6l0.1,0.2c0,0,0,0.1,0,0.1l0.4,1c0.1,0.2,0.1,0.3,0.2,0.5l0.1,0.2
    	c0,0.1,0,0.1,0.1,0.1l0.1,0.4c0,0,0,0.1,0,0.1l0.1,0.1c0,0,0,0.1,0,0.1l0.1,0.3c0,0.1,0.1,0.2,0.1,0.4l0,0.1c0,0.1,0.1,0.2,0.1,0.3
    	l0,0c0.1,0.1,0.1,0.2,0.1,0.4l0.1,0.2c0,0,0,0.1,0,0.1l0.1,0.3c0.1,0.2,0.1,0.4,0.2,0.5l0,0.1l0,0c0,0.1,0.1,0.2,0.1,0.3l0.1,0.3
    	c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.3,0.1,0.4l0.7,2.1c0.1,0.2,0.1,0.4,0.2,0.7l0.3,1.1c0,0.1,0,0.2,0.1,0.2l0.1,0.2
    	c0,0.1,0.1,0.2,0.1,0.4l0.1,0.3c0,0.1,0.1,0.3,0.1,0.4l0.3,1.1c0.1,0.2,0.1,0.5,0.2,0.8l0.1,0.4c0.1,0.3,0.1,0.5,0.2,0.8
    	c0,0.1,0.1,0.2,0.1,0.4l0.6,2c0,0.1,0,0.2,0.1,0.3l0.2,0.9c0,0.1,0,0.1,0.1,0.2l0,0.1c0.1,0.3,0.1,0.6,0.2,0.9l0.3,1.2
    	c0,0.1,0,0.2,0.1,0.2l0,0c0.1,0.3,0.2,0.6,0.2,0.9l0.6,2.4c0,0.1,0,0.2,0.1,0.2l0,0c0.1,0.3,0.1,0.6,0.2,0.9c0,0.1,0,0.1,0,0.2
    	l0.6,2.4c0,0.2,0.1,0.3,0.1,0.5l0.1,0.5c0,0.1,0,0.1,0,0.2l0.1,0.4c0,0.2,0.1,0.3,0.1,0.5l0,0.2c0,0.2,0.1,0.3,0.1,0.5l0.1,0.3
    	c0,0.1,0,0.1,0,0.2l0,0.1c0.1,0.2,0.1,0.5,0.2,0.7l0.2,0.8c0.2,1.2,0.5,2.4,0.7,3.6l0.2,1.2c0,0.3,0.1,0.5,0.1,0.8l0.1,0.5
    	c0,0.1,0,0.2,0.1,0.4l0,0.1c0,0.2,0.1,0.4,0.1,0.6c0,0.2,0,0.3,0.1,0.5c0,0.2,0.1,0.4,0.1,0.6c0,0.1,0,0.1,0,0.2l0,0.1
    	c0,0.2,0,0.4,0.1,0.5l0,0.4c0,0.2,0,0.5,0.1,0.7l0,0.1c0,0.2,0,0.4,0.1,0.7l0,0.1c0,0.2,0,0.4,0,0.6l0,0.3l0,0.1c0,0.1,0,0.2,0,0.3
    	c-0.4,0.2-1.1,0.5-2.3,1l-0.2,0c-3.8,1.2-8.6,3-13.8,5.2c-3.3,1.4-6.7,2.9-10,4.5c-2.4,1.1-4.5,2.2-6.1,3c-0.1,0-0.2,0.1-0.3,0.1
    	c-0.9,0.4-1.6,0.7-2.1,0.9c-0.1-0.1-0.1-0.2-0.2-0.4l-1.3-2.2l0,0c-0.1-0.3-0.3-0.6-0.4-0.8c-0.7-1.8-7.2-13.7-8-15.8
    	c-1.1-2.9-2.3-5.9-3.2-8.1c7-5.9,9.8-13,7-17.9c-3.5-6.1-14.4-6.2-24.8-0.2c-10.4,6-15.8,15.5-12.3,21.6c2.6,4.6,9.8,5.9,17.9,3.2
    	c0.6,0.7,1.2,1.4,1.9,2.2c2.8,3.3,12.3,17.7,15,21.4c0.1,0.2,0.2,0.4,0.4,0.6c0.2,0.4,0.3,0.6,0.4,0.8c-0.1,0.1-0.3,0.2-0.5,0.4
    	c-3,1.8-7.1,4.6-11.7,8c-5.9,4.3-11.5,8.8-15.4,12.4l-0.2,0.2c-1.1,0.8-1.6,1.2-1.9,1.4l-0.6-0.4c-0.6-0.4-1.2-0.8-1.8-1.2
    	c-0.7-0.5-1.5-1.1-2.4-1.8c-0.3-0.3-0.6-0.5-1-0.8l-1.4-1.1l0,0c0,0-0.1-0.1-0.1-0.1l-0.6-0.5c-0.1-0.1-0.2-0.2-0.4-0.3l-0.2-0.1
    	c-0.1-0.1-0.2-0.2-0.3-0.3l-0.1-0.1c-0.1-0.1-0.3-0.3-0.4-0.4c-0.2-0.2-0.3-0.3-0.5-0.5c-0.1-0.1-0.2-0.2-0.3-0.3l-0.2-0.2
    	c-0.2-0.2-0.3-0.3-0.5-0.5l-0.5-0.5c0,0,0,0-0.1-0.1l-0.6-0.6c-0.1-0.1-0.2-0.2-0.3-0.3l-0.1,0c0,0-0.1-0.1-0.1-0.1l-0.9-0.8
    	c-0.1-0.1-0.1-0.1-0.2-0.2l-1.5-1.4c-0.1-0.1-0.2-0.2-0.4-0.3c-0.1-0.1-0.2-0.2-0.3-0.3l-0.2-0.2c-0.1-0.1-0.1-0.1-0.2-0.2l0,0
    	c-0.1-0.1-0.2-0.2-0.4-0.3c-0.1-0.1-0.2-0.2-0.3-0.2l-1.6-1.5c-0.1-0.1-0.3-0.3-0.5-0.4l-3.4-3.4c-0.2-0.2-0.4-0.4-0.5-0.5l0,0
    	c-0.2-0.2-0.3-0.3-0.5-0.5l-0.5-0.5c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1l-0.9-0.9c0,0-0.1-0.1-0.1-0.1l-0.2-0.2
    	c-0.1-0.1-0.3-0.3-0.4-0.4L96.7,90c-0.1-0.1-0.2-0.2-0.3-0.3L96,89.2c-0.1-0.1-0.2-0.2-0.3-0.3l-0.7-0.8c-0.1-0.1-0.2-0.2-0.2-0.3
    	l-0.2-0.3c0,0-0.1-0.1-0.1-0.1L94,87.1c-0.1-0.1-0.2-0.2-0.3-0.3l-0.2-0.2c-0.1-0.1-0.1-0.1-0.2-0.2l-0.7-0.8c0,0-0.1-0.1-0.1-0.1
    	l-0.8-0.9c-0.1-0.1-0.1-0.1-0.1-0.2l0,0c-0.1-0.1-0.2-0.2-0.3-0.3L91.2,84c0,0-0.1-0.1-0.1-0.1L90.3,83c0,0-0.1-0.1-0.1-0.1l-0.9-1
    	c-0.1-0.1-0.1-0.2-0.2-0.3l-0.4-0.5c0,0,0,0,0,0l-0.1-0.2l-0.1-0.1L88,80.1c0,0-0.1-0.1-0.1-0.1l-0.1-0.2c-0.2-0.2-0.4-0.5-0.5-0.7
    	l0-0.1c-0.5-0.6-0.9-1.3-1.4-1.9c-0.2-0.3-0.4-0.6-0.6-0.9c-0.4-0.6-0.7-1.2-1.1-1.8c-1-1.7-1.7-3.2-2-4.5l0-0.1
    	c-0.3-1.1-0.2-1.6-0.2-1.8C82,67.7,82,67.4,82,67c0-0.1,0-0.2,0-0.3c0-0.3-0.1-0.5-0.2-0.8c-0.1-0.1-0.1-0.3-0.2-0.4
    	c-0.2-0.4-0.3-0.7-0.5-1.1c-0.2-0.3-0.3-0.5-0.5-0.8c-0.1-0.2-0.3-0.4-0.4-0.6L80,63l0,0l0,0v0c-0.1-0.1-0.2-0.3-0.4-0.4l0,0
    	c0,0-0.1-0.1-0.1-0.1l-0.2-0.2l-0.1,0c-0.3-0.3-0.7-0.4-1.1-0.5l-0.1,0c-0.1,0-0.2,0-0.3,0l-0.1,0c0,0-0.1,0-0.2,0h0
    	c-0.3,0-0.5,0-0.8,0.1c0,0-0.1,0-0.1,0l-0.1,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.2,0.1c-0.1,0-0.3,0.1-0.5,0.1l-0.1,0
    	c-0.2,0.1-0.5,0.2-0.7,0.3l-0.4,0.2c-0.2,0.1-0.4,0.2-0.6,0.3L73.2,63c0,0-0.1,0.1-0.1,0.1c-0.9,0.5-1.8,1.2-2.8,1.9
    	c-0.9,0.7-1.9,1.6-2.8,2.5c-0.1,0.1-0.3,0.3-0.4,0.4l0,0c-0.6,0.7-1.3,1.4-1.8,2.1c-0.1,0.1-0.2,0.3-0.3,0.4
    	c-0.2,0.2-0.3,0.4-0.5,0.6c-0.1,0.2-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.4-0.4,0.5l-0.1,0.2c-0.6,0.9-1,1.7-1.3,2.5l-0.1,0.3
    	c0,0.1-0.1,0.2-0.1,0.3l0,0.1c-0.1,0.2-0.1,0.4-0.2,0.5c0,0.2-0.1,0.3-0.1,0.5c-0.1,0.3-0.1,0.6-0.1,0.8c0,0.2,0,0.4,0,0.6
    	c0.1,0.6,0.3,1.1,0.6,1.4l0.2,0.3l0.2,0.1c1.6,1.5,3.6,2.4,5.1,2.3c0.1,0,0.2,0,0.3,0l0.1,0c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0,0.3,0
    	c0.1,0,0.2,0,0.3,0c0.2,0,0.3,0,0.5,0.1c0.1,0,0.2,0,0.3,0.1c0.1,0,0.2,0,0.3,0.1c0.2,0.1,0.4,0.1,0.7,0.2c0.1,0,0.2,0.1,0.4,0.1
    	c0.2,0.1,0.3,0.1,0.5,0.2l0.1,0c0.2,0.1,0.4,0.2,0.6,0.2c0.4,0.2,0.9,0.4,1.4,0.7c0.1,0.1,0.3,0.1,0.4,0.2c0.3,0.2,0.6,0.3,0.9,0.5
    	c0.2,0.1,0.3,0.2,0.5,0.3c0.3,0.2,0.6,0.4,0.9,0.5c0.2,0.1,0.3,0.2,0.5,0.3l0.3,0.2c0.1,0.1,0.3,0.2,0.4,0.3
    	c0.3,0.2,0.6,0.4,0.9,0.7c0.3,0.2,0.6,0.5,0.9,0.7l0.5,0.4c0.2,0.2,0.4,0.3,0.6,0.5l0.3,0.2c0.1,0.1,0.3,0.2,0.4,0.3l0.4,0.3
    	c0.1,0.1,0.3,0.2,0.4,0.3l0.3,0.2c0.1,0.1,0.2,0.2,0.4,0.3l0.6,0.5c0.1,0.1,0.2,0.1,0.3,0.2l0.3,0.3c0.1,0.1,0.2,0.1,0.2,0.2l0,0.1
    	l0.4,0.3c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.2,0.3,0.3l0.4,0.4c0.2,0.1,0.3,0.3,0.5,0.4l0.2,0.2c0,0,0.1,0.1,0.1,0.1l1.4,1.2
    	c0.1,0.1,0.2,0.2,0.3,0.3l0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3l2,1.9c0.2,0.2,0.4,0.4,0.6,0.6c0.1,0.1,0.2,0.2,0.3,0.2l0.9,0.8
    	c0.2,0.2,0.4,0.4,0.6,0.6c0,0.1,0.1,0.1,0.1,0.1l1.6,1.6c0.1,0.1,0.1,0.1,0.2,0.2l0,0c0.1,0.1,0.2,0.2,0.4,0.3l1.1,1.1
    	c0,0.1,0.1,0.1,0.1,0.1l0.1,0.1c0.2,0.2,0.4,0.4,0.6,0.6l0.2,0.2c0.2,0.2,0.4,0.4,0.7,0.7c0.1,0.1,0.1,0.1,0.2,0.2l1.5,1.5
    	c0.1,0.1,0.1,0.1,0.2,0.2l0.8,0.9c0.2,0.2,0.4,0.5,0.6,0.7l0.3,0.3c0.2,0.2,0.4,0.4,0.5,0.6l0.2,0.2c0.2,0.2,0.4,0.5,0.7,0.7
    	c0,0,0.1,0.1,0.1,0.1l0.8,0.9c0.1,0.1,0.2,0.3,0.4,0.4l0.3,0.3c0.1,0.1,0.1,0.1,0.2,0.2l0.6,0.6c0,0,0.1,0.1,0.1,0.1l0,0l0.4,0.4
    	c0.9,1,1.6,1.8,2.3,2.7l0.1,0.1l0.1,0.1c0.2,0.2,0.3,0.4,0.5,0.6l0.2,0.3c0.2,0.2,0.4,0.5,0.6,0.7l0.3,0.3c0.1,0.1,0.1,0.1,0.2,0.2
    	l0.3,0.4c0.1,0.1,0.1,0.2,0.2,0.2l0.9,1.2c0.1,0.2,0.2,0.3,0.4,0.5l0.1,0.1c0,0,0.1,0.1,0.1,0.1l0,0.1c0.1,0.1,0.2,0.2,0.2,0.3
    	l0.1,0.2c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0l0,0.1c0.1,0.2,0.3,0.4,0.4,0.6c0,0,0,0,0,0.1l0.1,0.1c0.1,0.2,0.2,0.3,0.3,0.5l0.2,0.3
    	c0,0,0,0,0,0.1c0,0.1,0.1,0.2,0.1,0.3c-0.3,0.5-0.8,1.2-1.5,2l-0.1,0.1c-2.7,2.9-6,6.9-9.4,11.4c-2.2,2.9-4.4,5.9-6.4,8.9
    	c-1.6,2.2-2.9,4.2-3.8,5.7c-0.1,0.1-0.1,0.2-0.2,0.3c-0.6,0.9-1.1,1.5-1.4,1.9c-0.1-0.1-0.3-0.1-0.4-0.2l-1.1-0.7l-1.3-0.8l0,0
    	c-0.2-0.1-0.4-0.2-0.5-0.3c-1.4-1.1-3.1-2.4-4.8-3.8c-2.5-2-5.1-4.1-7-5.6c3.1-8.5,1.9-16.2-2.9-19c-3-1.7-7-1.4-11.1,1.1
    	c-3.9,2.3-7.7,6.3-10.5,11.1c-2.8,4.9-4.4,10.1-4.4,14.6c0,4.8,1.6,8.4,4.6,10.2c4.6,2.7,11.4,0.2,17.2-6.2c0.8,0.3,1.7,0.6,2.7,1
    	c4.1,1.5,9.7,3.5,13.9,5.4c0.2,0.1,0.4,0.2,0.5,0.3l0.1,0c0.4,0.2,0.6,0.4,0.8,0.5c-0.1,0.1-0.1,0.3-0.2,0.5c-1.7,3-3.9,7.6-6.2,13
    	c-3,6.7-5.5,13.2-7.1,18.3l0,0.1c0,0.1,0,0.1-0.1,0.2c-0.5,1.2-0.8,1.9-1,2.2l-0.7,0c-0.7,0-1.5-0.1-2.5-0.2
    	c-0.7-0.1-1.6-0.2-2.6-0.3l-0.4-0.1c-0.3,0-0.6-0.1-0.9-0.1l-1-0.2l0,0l-0.3,0c-0.2,0-0.4-0.1-0.6-0.1c-0.1,0-0.2,0-0.3-0.1
    	c-0.1,0-0.2,0-0.3-0.1c-0.2,0-0.4-0.1-0.6-0.1l-0.7-0.1c-0.2,0-0.4-0.1-0.7-0.1c-0.2,0-0.4-0.1-0.7-0.1c-0.2,0-0.4-0.1-0.7-0.1
    	l-1.4-0.3c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3-0.1l-0.3-0.1c-0.2,0-0.4-0.1-0.5-0.1l-0.3-0.1c-0.2,0-0.3-0.1-0.5-0.1l-0.1,0
    	c-0.2,0-0.3-0.1-0.5-0.1l-0.1,0c-0.1,0-0.2-0.1-0.4-0.1l-1.5-0.3c-0.2,0-0.3-0.1-0.5-0.1c-0.2,0-0.3-0.1-0.4-0.1
    	c-0.1,0-0.2,0-0.3-0.1l-0.7-0.2c-0.2,0-0.4-0.1-0.5-0.1c-0.1,0-0.3-0.1-0.4-0.1l-0.6-0.2c-0.1,0-0.2-0.1-0.4-0.1l-0.2-0.1
    	c-0.1,0-0.2,0-0.3-0.1l-0.6-0.2c-0.2-0.1-0.4-0.1-0.6-0.2l-0.9-0.2c-0.2-0.1-0.5-0.1-0.7-0.2c-0.1,0-0.2-0.1-0.3-0.1l-1.3-0.3
    	c-0.2,0-0.3-0.1-0.5-0.1l-1-0.3c-0.1,0-0.3-0.1-0.4-0.1c-0.2,0-0.3-0.1-0.5-0.1c-0.2-0.1-0.4-0.1-0.5-0.2l-0.1,0
    	c-0.1,0-0.3-0.1-0.4-0.1l-0.6-0.2c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.4-0.1l-1-0.3c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.1,0
    	c-0.1,0-0.3-0.1-0.4-0.1l-1.1-0.3c-0.1,0-0.3-0.1-0.4-0.1l-1.2-0.4c0,0-0.1,0-0.1,0l-0.1,0c-0.1,0-0.2-0.1-0.3-0.1l-0.3-0.1
    	c-0.2-0.1-0.5-0.2-0.7-0.2l-0.2-0.1c0,0-0.1,0-0.1,0l-0.4-0.1c-0.2-0.1-0.5-0.2-0.7-0.2c-0.1,0-0.2-0.1-0.3-0.1l-0.5-0.2
    	c-0.3-0.1-0.5-0.2-0.8-0.3l-0.5-0.2c0,0,0,0-0.1,0l-0.1,0c-0.2-0.1-0.3-0.1-0.5-0.2l-0.2-0.1c-0.1,0-0.2-0.1-0.4-0.1l-0.9-0.3
    	l-0.3-0.1c0,0-0.1,0-0.1,0l-0.2-0.1c-0.2-0.1-0.4-0.1-0.6-0.2l-0.1,0c-0.1,0-0.1,0-0.1-0.1l-0.2-0.1c-0.1,0-0.1,0-0.2-0.1l-0.8-0.3
    	c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1-0.1l-0.1,0c-0.3-0.1-0.7-0.3-1-0.4c-0.7-0.3-1.3-0.6-1.8-0.8c-0.1-0.1-0.2-0.1-0.3-0.2
    	c-4.6-2.2-7-4.3-7.7-5.4c0,0,0-0.1-0.1-0.1c-0.1-0.2-0.2-0.3-0.2-0.4c-0.1-0.2-0.2-0.5-0.3-0.7c-0.1-0.2-0.2-0.3-0.4-0.5
    	c-0.1-0.1-0.1-0.1-0.2-0.2c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.2-0.3-0.2
    	c-0.1-0.1-0.2-0.1-0.4-0.2c-0.5-0.3-1.1-0.6-1.7-0.8c-0.2-0.1-0.4-0.2-0.7-0.2l-0.6-0.2l-0.1,0.1c-0.1,0-0.1,0-0.2,0
    	c-0.1,0-0.2,0-0.3,0c-0.4,0.1-0.7,0.2-1,0.4l-0.1,0c-0.3,0.2-0.7,0.5-1,0.8c-0.3,0.3-0.7,0.7-1,1.1c-0.2,0.2-0.3,0.4-0.5,0.7
    	c-0.1,0.2-0.2,0.3-0.3,0.5c-0.2,0.3-0.4,0.7-0.6,1.1c-0.5,0.9-0.9,1.8-1.2,2.7c-0.3,0.7-0.5,1.4-0.7,2.1c-0.1,0.4-0.2,0.7-0.3,1.1
    	c-0.1,0.4-0.2,0.8-0.3,1.1c-0.1,0.4-0.2,0.7-0.2,1.1c-0.5,2.5-0.6,4.9-0.4,6.7c0,0.3,0.1,0.5,0.1,0.8c0.2,0.8,0.4,1.4,0.8,2
    	c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.2,0.3,0.3,0.5,0.4c0.2,0.1,0.4,0.2,0.7,0.3l0,0c0.1,0,0.3,0.1,0.4,0.1c0.3,0.1,0.6,0.1,0.8,0.1
    	c1.6,0.2,3.2,0,4.2-0.6c0.2-0.1,0.4-0.2,0.6-0.4c0,0,0,0,0.1-0.1l0,0c0.6-0.5,2.3-1,5.4-1.2c0.3,0,0.6,0,1,0c1,0,2.1,0,3.2,0.1
    	c0.8,0,1.5,0.1,2.3,0.2l0.1,0c0.4,0,0.7,0.1,1.1,0.1l0.2,0c0.3,0,0.5,0.1,0.8,0.1l0.1,0c0.1,0,0.2,0,0.3,0l2.3,0.4
    	c0.1,0,0.2,0,0.2,0l0.1,0c0.3,0.1,0.6,0.1,0.9,0.2c0.1,0,0.1,0,0.2,0l0.2,0c0.4,0.1,0.8,0.2,1.2,0.2l0.3,0.1c0.3,0.1,0.6,0.1,1,0.2
    	l0.1,0c0,0,0.1,0,0.1,0c0.1,0,0.3,0.1,0.4,0.1c0.3,0.1,0.7,0.1,1,0.2l0.2,0l0.1,0l0.2,0c0,0,0.1,0,0.1,0c0.4,0.1,0.7,0.2,1.1,0.3
    	l0.9,0.2c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.4,0.1,0.6,0.1c0.4,0.1,0.8,0.2,1.2,0.3c0.1,0,0.2,0,0.3,0.1l1.9,0.5
    	c0.2,0.1,0.5,0.1,0.7,0.2c0.2,0,0.4,0.1,0.5,0.1l0.4,0.1c0.1,0,0.3,0.1,0.4,0.1l0.1,0c0.2,0,0.3,0.1,0.5,0.1l0.2,0
    	c0.3,0.1,0.5,0.1,0.8,0.2c0.2,0,0.3,0.1,0.5,0.1l0.8,0.2c0.1,0,0.2,0.1,0.2,0.1l2.1,0.6c0.1,0,0.2,0,0.2,0.1l0.1,0
    	c0.3,0.1,0.5,0.2,0.8,0.2l0.3,0.1c0.3,0.1,0.6,0.2,0.9,0.3l0.2,0.1c0.3,0.1,0.6,0.2,0.9,0.3c0.1,0,0.2,0.1,0.2,0.1l1.1,0.3
    	c0.3,0.1,0.6,0.2,0.9,0.3l0.3,0.1c0.3,0.1,0.5,0.2,0.8,0.2l0.1,0c0,0,0.1,0,0.2,0.1l0.1,0c0.3,0.1,0.5,0.2,0.8,0.2l0.3,0.1
    	c0.3,0.1,0.5,0.2,0.8,0.3c0.1,0,0.1,0.1,0.2,0.1c0.2,0.1,0.4,0.1,0.5,0.2l0,0l1,0.3c1.2,0.4,2.3,0.8,3.4,1.2l0.3,0.1
    	c0.1,0,0.3,0.1,0.4,0.2c0.2,0.1,0.4,0.1,0.6,0.2l0.6,0.2c0,0,0.1,0,0.1,0l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.2l0.2,0.1
    	c0.1,0,0.2,0.1,0.3,0.1l0,0c0.2,0.1,0.4,0.1,0.6,0.2l0.2,0.1c0.1,0,0.2,0.1,0.3,0.1c0,0,0.1,0,0.1,0.1c0.1,0.1,0.3,0.1,0.4,0.2
    	l1.4,0.6c0.1,0,0.1,0,0.1,0.1l0.1,0.1c0.1,0,0.2,0.1,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1l0.1,0.1c0,0,0,0,0,0l0,0
    	c0.2,0.1,0.4,0.2,0.6,0.3l0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0,0.6-0.1,1.5-0.3,2.5l0,0.2c-0.9,3.9-1.7,8.9-2.5,14.6
    	c-0.5,3.5-0.8,7.2-1.1,10.9c-0.2,2.7-0.4,5-0.4,6.8c0,0.1,0,0.1,0,0.2l0,0.1c-0.1,1-0.2,1.8-0.3,2.3c-0.1,0-0.3,0-0.5,0h-1.3
    	c-0.1,0-0.1,0-0.2,0l-0.9,0c-0.4,0-0.8,0-1.2,0c-1.8-0.3-4-0.6-6.2-0.9c-3.1-0.5-6.3-1-8.7-1.3c-1.6-9-6.4-15-12-15
    	c-3.5,0-6.7,2.3-9.1,6.5c-2.3,4-3.5,9.3-3.5,14.9c0,5.6,1.2,10.9,3.5,14.9c2.4,4.2,5.6,6.5,9.1,6.5c5.3,0,10-5.6,11.8-13.9
    	c0.9-0.2,1.8-0.3,2.8-0.5c4.3-0.8,10.1-1.9,14.7-2.2c0.3,0,0.5,0,0.7,0c0.4,0,0.7,0,0.9,0c0,0.1,0.1,0.3,0.1,0.6
    	c0,3.5,0.4,8.6,1.1,14.3c0.8,7.3,1.9,14.2,3,19.3l0,0.1c0,0.1,0,0.1,0,0.2l0,0.1c0.2,1.3,0.3,2,0.3,2.4l-0.6,0.3
    	c-0.5,0.3-1.2,0.6-1.9,1c-0.8,0.3-1.7,0.7-2.8,1.2l-0.3,0.1c-0.3,0.1-0.6,0.2-0.9,0.4l-0.5,0.2l0,0h0l0,0c-0.2,0.1-0.4,0.1-0.6,0.2
    	c-0.2,0.1-0.4,0.1-0.5,0.2c-0.1,0-0.2,0.1-0.3,0.1L58,295c-0.1,0-0.3,0.1-0.4,0.1l-0.2,0.1c-0.1,0-0.2,0.1-0.4,0.1l-0.3,0.1
    	c-0.2,0.1-0.4,0.1-0.6,0.2l0,0c-0.2,0.1-0.4,0.1-0.7,0.2l-0.8,0.2c-0.2,0.1-0.4,0.1-0.6,0.2l-2.3,0.7c-0.2,0.1-0.3,0.1-0.5,0.2
    	c-0.2,0-0.3,0.1-0.5,0.1l-2.3,0.7c-0.1,0-0.2,0.1-0.3,0.1l-0.2,0.1c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0.1-0.4,0.1-0.6,0.2l-0.8,0.2
    	c-0.2,0.1-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.1-0.7,0.2l-4.9,1.3c-0.1,0-0.3,0.1-0.4,0.1l-0.8,0.2c-0.2,0.1-0.4,0.1-0.6,0.2l-1.6,0.4
    	c-0.1,0-0.1,0-0.2,0l-0.8,0.2c-0.1,0-0.2,0.1-0.4,0.1c-0.1,0-0.2,0.1-0.4,0.1l-0.1,0c-0.2,0-0.4,0.1-0.6,0.1l-0.1,0
    	c-0.1,0-0.2,0.1-0.3,0.1l-1,0.2c-0.1,0-0.2,0-0.3,0.1l-0.3,0.1c-0.1,0-0.1,0-0.2,0l-0.1,0c-0.1,0-0.3,0.1-0.4,0.1l-0.1,0
    	c-0.1,0-0.1,0-0.2,0.1l-0.1,0c-0.1,0-0.1,0-0.2,0l-0.2,0c-0.1,0-0.1,0-0.2,0.1l-0.8,0.2c-0.1,0-0.2,0-0.3,0.1l-0.8,0.2
    	c0,0-0.1,0-0.1,0l-0.6,0.1c-0.2,0-0.4,0.1-0.6,0.1l-0.1,0c-0.1,0-0.2,0-0.2,0l-1,0.2c0,0-0.1,0-0.1,0c0,0,0,0,0,0l-0.5,0.1
    	c-0.2,0-0.4,0.1-0.5,0.1c-0.1,0-0.1,0-0.2,0l-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1l-0.2-0.1l-0.2,0.1c-0.1,0-0.1,0-0.2,0h0
    	c-0.2,0-0.3,0-0.5,0.1l-0.8,0.1c-0.3,0-0.5,0.1-0.7,0.1l-0.2,0l0,0c-0.6,0.1-1.2,0.1-1.7,0.2c-0.5,0-1,0.1-1.5,0.1
    	c-0.5,0-0.9,0-1.5,0c-1.5,0-2.8-0.1-3.9-0.2c-0.6-0.1-1.1-0.2-1.6-0.3c-0.2-0.1-0.4-0.1-0.6-0.2c-0.8-0.3-1.1-0.5-1.2-0.6
    	c-0.3-0.3-0.8-0.6-1.4-0.8l-0.3-0.1c-0.5-0.1-1-0.2-1.5-0.2H5.1H4.9l-0.4,0c-0.4,0-0.7,0.1-1.1,0.1c-0.2,0-0.5,0.1-0.7,0.2l-0.2,0
    	l-0.2,0.1c-0.5,0.2-0.9,0.5-1.2,0.9c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.1-0.1,0.2-0.2,0.4c0,0.1-0.1,0.2-0.1,0.3
    	c-0.1,0.4-0.3,0.8-0.3,1.2c0,0.1,0,0.3-0.1,0.4c0,0.2-0.1,0.5-0.1,0.8c0,0.1,0,0.2,0,0.4c0,0,0,0.1,0,0.1l0,0.3c0,0.1,0,0.2,0,0.4
    	v0.1C0,308.6,0,308.9,0,309.1 M162.1,98.4c83.1-48,189.7-19.4,237.7,63.7c48,83.1,19.4,189.7-63.7,237.7
    	c-83.1,48-189.7,19.4-237.7-63.7C50.4,253,79,146.4,162.1,98.4 M292.2,161.4c11.9-6.9,16-22.1,9.1-34c-6.9-11.9-22.1-16-34-9.1
    	c-11.9,6.9-16,22.1-9.1,34C265.1,164.1,280.3,168.2,292.2,161.4 M202.5,237.2c5.5,9.5,17.7,12.8,27.2,7.3
    	c9.5-5.5,12.8-17.7,7.3-27.2c-5.5-9.5-17.7-12.8-27.2-7.3C200.3,215.5,197,227.7,202.5,237.2 M151.9,203.1
    	c9.5-5.5,12.8-17.7,7.3-27.2c-5.5-9.5-17.7-12.8-27.2-7.3c-9.5,5.5-12.8,17.7-7.3,27.2C130.2,205.3,142.3,208.6,151.9,203.1
    	 M336,309.2c-8.3,4.8-11.2,15.5-6.4,23.8c4.8,8.3,15.5,11.2,23.8,6.4c8.3-4.8,11.2-15.5,6.4-23.8C355,307.2,344.3,304.4,336,309.2
    	 M204.9,300c-8.3,4.8-11.2,15.5-6.4,23.8c4.8,8.3,15.5,11.2,23.8,6.4c8.3-4.8,11.2-15.5,6.4-23.8C223.9,298,213.2,295.2,204.9,300
    	 M291.5,283.2c-9,5.2-12.1,16.8-6.9,25.8c5.2,9,16.8,12.1,25.8,6.9c9-5.2,12.1-16.8,6.9-25.8C312,281.1,300.5,278,291.5,283.2
    	 M348.3,243.7c9.2-5.3,12.3-17,7-26.2c-5.3-9.2-17-12.3-26.2-7c-9.2,5.3-12.3,17-7,26.2C327.5,245.9,339.2,249,348.3,243.7
    	 M131.5,322.7c-5.9,3.4-8,11-4.6,17c3.4,6,11.1,8,17,4.6c5.9-3.4,8-11,4.6-17C145,321.3,137.4,319.3,131.5,322.7 M263.6,356.8
    	c-6,3.4-8,11-4.6,17c3.4,5.9,11,8,17,4.6c5.9-3.4,8-11,4.6-17C277.1,355.4,269.5,353.3,263.6,356.8 M199.1,136.2c6-3.4,8-11,4.6-17
    	c-3.4-5.9-11-8-17-4.6c-5.9,3.4-8,11-4.6,17C185.6,137.6,193.2,139.6,199.1,136.2 M143.5,285.7c6-3.4,8-11,4.6-17
    	c-3.4-6-11-8-17-4.6c-6,3.4-8,11-4.6,17C129.9,287.1,137.5,289.1,143.5,285.7 M372.3,160.2c6-3.4,8-11,4.6-17c-3.4-6-11-8-17-4.6
    	c-6,3.4-8,11-4.5,17C358.7,161.6,366.3,163.6,372.3,160.2 M197.2,362.8c-5.9,3.4-8,11-4.6,17c3.4,6,11,8,17,4.6c6-3.4,8-11,4.6-17
    	C210.7,361.5,203.1,359.4,197.2,362.8 M291.9,228.7c6-3.4,8-11,4.6-17c-3.4-5.9-11-8-17-4.6c-6,3.4-8,11-4.6,17
    	C278.3,230.1,285.9,232.2,291.9,228.7 M401.1,291.7c6-3.4,8-11.1,4.6-17c-3.4-5.9-11-8-17-4.6c-6,3.4-8,11-4.6,17
    	C387.6,293.1,395.2,295.1,401.1,291.7" />
    </svg>
)

export default Covid19
